import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Chip, CircularProgress } from "@material-ui/core";
import InputSelect from "../Inputs/InputSelect";
import Compressor from "compressorjs";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  label: {
    marginTop: 20,
    marginLeft: 20,
    fontSize: 22,
    color: "rgb(134 134 134)",
    marginBottom: "-12px",
  },
  fileInput: {
    borderRadius: 25,
    backgroundColor: "#00638F",
    width: "50%",
    color: "#ffffff",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column !important",
    flexWrap: "wrap",
    color: "#ffffff",
  },
}));

const filter = createFilterOptions();

export default function UpdateMaintenance({ firebase }) {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { shipId, eventId } = useParams();
  const [date, setDate] = useState();
  const [types, setTypes] = useState([]);
  const [titles, setTitles] = useState([]);
  const [availableTitles, setAvailablesTitles] = useState([]);
  const [baseDescriptions, setBaseDescriptions] = useState([]);
  const [availableDescriptions, setAvailableDescriptions] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [recurrence_unit, setRecurrenceUnit] = useState("");
  const [recurrence, setRecurrence] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState();
  const [photo, setPhoto] = useState("");
  const history = useHistory();

  const handleSubmit = async (values) => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .doc(eventId)
      .update({
        date: moment(values.date).format("YYYY/MM/DD"),
        type: type?.value || "",

        title: title?.value || "",
        descriptions: descriptions || [],
        descriptionDetail: values?.commentaire || "",
        recurrence: values?.recurrence || "",
        recurrence_unit: recurrence_unit || "",
        validated: false,
        image: photo,
      })
      .then(() => {
        enqueueSnackbar("Évènement modifié !", { variant: "success" });
        history.push("/ship/" + shipId + "/maintenance");
      })
      .catch((err) => {
        enqueueSnackbar("Erreur lors de la modification de l'évènement", {
          variant: "error",
        });
        console.log(err);
      });
  };

  const compressFile = (file, quality) => {
    if (quality < 0) {
      enqueueSnackbar("Fichier trop volumineux !", { variant: "error" });
      return;
    }
    const compressor = new Compressor(file, {
      quality: quality,
      convertSize: 1000000,
      maxHeight: 1000,
      maxWidth: 1000,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = function () {
          var base64data = reader.result;
          if (base64data.length * (3 / 4) < 1000000) {
            setPhoto(base64data);
          } else {
            compressFile(result, compressor.options.quality - 0.1);
          }
        };
      },
      error() {
        enqueueSnackbar("Échec de l'envoi du fichier  !", { variant: "error" });
      },
    });
  };

  const handleFile = (event) => {
    console.log(event.target.files);
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    compressFile(file, 0.8);
  };

  useEffect(() => {
    firebase.db
      .collection("Types")
      .orderBy("label")
      .get()
      .then((snapshot) => {
        let temp = [];

        snapshot.forEach((item) => {
          temp.push({
            label: item.data().label,
            value: item.data().label,
          });
        });
        setTypes(temp);
      });
    firebase.db
      .collection("Titles")
      .orderBy("label")
      .get()
      .then((snapshot) => {
        let temp = [];
        snapshot.forEach((item) => {
          temp.push({
            label: item.data().label,
            value: item.data().label,
            category: item.data().category,
          });
        });
        setTitles(temp);
      });
    firebase.db
      .collection("Descriptions")
      .orderBy("label")
      .get()
      .then((snapshot) => {
        let temp = [];
        snapshot.forEach((item) => {
          temp.push({
            label: item.data().label,
            value: item.data().label,
            category: item.data().category,
          });
        });
        setBaseDescriptions(temp);
      });

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .doc(eventId)
      .get()
      .then((doc) => {
        setDate(moment(doc.data().date).format("YYYY-MM-DD"));
        setType({ value: doc.data().type, label: doc.data().type });
        setTitle({
          value: doc.data().title,
          label: doc.data().title,
          category: doc.data().type,
        });
        setComment(doc.data().descriptionDetail);
        setRecurrence(doc.data().recurrence);
        setRecurrenceUnit(doc.data().recurrence_unit);
        setPhoto(doc.data().image);
        setValidated(doc.data().validated);
        setDescriptions(doc.data().descriptions);
        setAvailablesTitles(
          titles.filter((item) => item.category === doc.data().type)
        );
        setAvailableDescriptions(
          baseDescriptions.filter((item) => item.category === doc.data().title)
        );
        setLoading(false);
      });
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            textAlign: "center",
            margin: "50px auto",
          }}
        >
          MAINT & SEA
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ArrowBack color="primary" />}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Modifier un évènement
          </Typography>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="primary" style={{ margin: "auto" }} />
            </div>
          ) : (
            <FinalForm
              onSubmit={handleSubmit}
              style={classes.form}
              initialValues={{
                date: date,
                commentaire: comment,
                recurrence,
              }}
            >
              <p className={classes.label}>Date</p>
              <InputText name="date" required type="date"></InputText>

              <p className={classes.label}>Poste</p>
              <Autocomplete
                options={types}
                filterSelectedOptions
                autoHighlight
                handleHomeEndKeys
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginTop: 15 }}
                  />
                )}
                onChange={(event, newValue) => {
                  setType(newValue);
                  if (!!newValue)
                    setAvailablesTitles(
                      titles.filter((item) => item.category === newValue.value)
                    );
                }}
                value={type}
              />
              {!!type && (
                <>
                  <p className={classes.label}>Action</p>
                  <Autocomplete
                    options={availableTitles}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ marginTop: 15 }}
                      />
                    )}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    autoHighlight
                    handleHomeEndKeys
                    filterSelectedOptions
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          label: params.inputValue,
                        });
                      }

                      return filtered;
                    }}
                    value={title}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setTitle({
                          label: newValue,
                          value: newValue,
                        });
                        if (!!newValue)
                          setAvailableDescriptions(
                            baseDescriptions.filter(
                              (item) => item.category === newValue
                            )
                          );
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setTitle({
                          label: newValue.inputValue,
                          value: newValue.inputValue,
                        });
                        if (!!newValue)
                          setAvailableDescriptions(
                            baseDescriptions.filter(
                              (item) => item.category === newValue.inputValue
                            )
                          );
                      } else {
                        setTitle(newValue);
                        if (!!newValue)
                          setAvailableDescriptions(
                            baseDescriptions.filter(
                              (item) => item.category === newValue.value
                            )
                          );
                      }
                    }}
                  />
                  {!!title && (
                    <>
                      <p className={classes.label}>Détail de l'action</p>
                      <Autocomplete
                        options={availableDescriptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ marginTop: 15 }}
                          />
                        )}
                        freeSolo
                        selectOnFocus
                        autoHighlight
                        handleHomeEndKeys
                        clearOnBlur
                        multiple
                        disableCloseOnSelect
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          console.log(params);
                          // Suggest the creation of a new value
                          if (params.inputValue !== "") {
                            filtered.push({
                              value: params.inputValue,
                              label: params.inputValue,
                            });
                          }

                          return filtered;
                        }}
                        value={descriptions}
                        onChange={(event, newValue) => {
                          setDescriptions(newValue);
                        }}
                        renderTags={(descriptions, getTagProps) =>
                          descriptions.map((description, index) => (
                            <Chip
                              label={description.label}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                      />

                      <p className={classes.label}>Commentaire</p>
                      <InputText name="commentaire" multiline></InputText>

                      <p className={classes.label}>Périodicité</p>
                      <InputText name="recurrence" type="number"></InputText>
                      <Autocomplete
                        options={["Jours", "Mois", "Semaine", "Année"]}
                        filterSelectedOptions
                        autoHighlight
                        handleHomeEndKeys
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ marginTop: 15 }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setRecurrenceUnit(newValue);
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setRecurrenceUnit(newValue.inputValue);
                          } else {
                            setRecurrenceUnit(newValue);
                          }
                        }}
                        value={recurrence_unit}
                      />
                      {!!photo && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                          className={classes.btnWrapper}
                        >
                          <img
                            src={photo}
                            style={{
                              maxWidth: "80%",
                              maxHeight: "80%",
                              objectFit: "contain",
                              marginTop: 10,
                            }}
                          />
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        className={classes.btnWrapper}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          className={classes.submit}
                          onChange={handleFile}
                        >
                          {photo ? "Modifier la" : "Ajouter une"} photo
                          <input type="file" hidden accept="image/*" />
                        </Button>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Modifier
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </FinalForm>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
