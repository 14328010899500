import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI, { URL as _URL } from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import {
  Redirect,
  useHistory,
  Link,
  useRouteMatch,
  Switch,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InfosRecap from "./HomeComponents/InfosRecap";
import CahierTableau from "./HomeComponents/CahierTable";
import MaintenanceTableau from "./HomeComponents/MaintenanceTable";
import MyCalendar from "./HomeComponents/MyCalendar";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Datatable from "../Utils/Datatable";
import { Visibility } from "@material-ui/icons";
import axios from "axios";
import InputSelect from "../Inputs/InputSelect";
import moment from "moment";
import Rapport from "../Components/Rapport";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
} from "recharts";
import html2canvas from "html2canvas";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  navbar: {
    padding: theme.spacing(0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  button: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",
    fontWeight: "normal",
    color: "#00638F",
    minWidth: 180,
    flex: 1,
  },
  inputSelect: {
    width: "100%",
    height: 56,
    borderRadius: 50,
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 15,
    paddingLeft: 10,
    borderColor: "rgba(0, 0, 0, 0.2)",
    marginTop: 10,
  },
  selectedButton: {
    flex: 1,
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",
    fontWeight: "normal",
    color: "#FFF",
    backgroundColor: "#00638F",
    "&:hover": {
      backgroundColor: "#00638F",
    },
    minWidth: 180,
  },
  grid: {
    width: "100%",
  },
}));

export default function Rapports({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const [lines, setLines] = useState([]);
  const shipsRef = firebase.db.collection("Ships");
  const [loading, setLoading] = useState(false);
  const [cahierCharts, setCahierCharts] = useState([]);
  const [maintenanceCharts, setMaintenanceCharts] = useState([]);
  const [yearsBetweenSelected, setYearsBetweenSelected] = useState([]);
  const [hydroCharts, setHydroCharts] = useState([]);
  const [oilCharts, setOilCharts] = useState([]);
  const [pdf, setPdf] = useState(null);
  let isBlobLoading = false;
  const printRef = React.useRef();
  const printRefMaintenance = React.useRef();
  const printRefHydro = React.useRef();
  const printRefOil = React.useRef();
  const handleRefus = async (id) => {
    await firebase.db.collection("rapports").doc(id).update({
      status: "rejected",
    });
    enqueueSnackbar("Demande de rapport refusée", { variant: "success" });
  };

  const handleAccept = async ({ start, end, id, shipId, ship }) => {
    if (loading) {
      enqueueSnackbar("Un rapport est déjà en cours de génération !", {
        variant: "warning",
      });
      return;
    }

    enqueueSnackbar("Génération du pdf en cours", { variant: "info" });
    setLoading(true);
    try {
      const startDate = start.replaceAll("-", "/");
      const endDate = end.replaceAll("-", "/");
      console.log(startDate, endDate);

      function generateYearsBetween(
        startYear = parseInt(startDate.split("/")[0]),
        endYear = parseInt(endDate.split("/")[0])
      ) {
        const endDate = endYear || new Date().getFullYear();
        let years = [];

        for (var i = startYear; i <= endDate; i++) {
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          years.push({
            year: startYear,
            color: randomColor,
          });
          startYear++;
        }
        return years;
      }

      const yearsBetween = generateYearsBetween();
      setYearsBetweenSelected(generateYearsBetween());

      const cahierDocs = await shipsRef
        .doc(shipId)
        .collection("event_cahier")
        .where("date", ">=", startDate)
        .where("date", "<=", endDate)
        .orderBy("date", "asc")
        .get();
      const maintenanceDocs = await shipsRef
        .doc(shipId)
        .collection("event_maintenance")
        .where("date", ">=", startDate)
        .where("date", "<=", endDate)
        .orderBy("date", "asc")
        .get();
      const hydroDocs = await shipsRef
        .doc(shipId)
        .collection("event_hydro")
        .where("date", ">=", startDate)
        .where("date", "<=", endDate)
        .orderBy("date", "asc")
        .get();

      // cahier chart
      let compteur = [];
      cahierDocs.forEach((el, i) => {
        const data = el.data();
        let smallName = data.equipement;
        switch (data.equipement) {
          case "EQUIPEMENT GUINDEAU":
            smallName = "GUINDEAU";
            break;
          case "GROUPE CHAUD":
            smallName = "GRP CHAUD";
            break;
          case "AUXILIAIRES MACHINES (pompes, hydrophore, hydraulique)":
            smallName = "AUX MACH.";
            break;
        }
        if (data.equipement) {
          const i = compteur.findIndex((e) => e.name === smallName);
          if (i > -1) {
            compteur[i].Nombre = compteur[i].Nombre + 1;
          } else {
            compteur.push({
              name: smallName,
              Nombre: 1,
            });
          }
        }
      });

      // maintenace chart
      let compteurMaintenance = [];
      maintenanceDocs.forEach((el, i) => {
        const data = el.data();
        let smallName = data.type;
        if (data.type) {
          const i = compteurMaintenance.findIndex((e) => e.name === smallName);
          const year = data.date.split("/")[0];
          if (i > -1) {
            compteurMaintenance[i][`${year}`] =
              compteurMaintenance[i][`${year}`] + 1;
          } else {
            let maintenanceObject = {
              name: smallName,
            };

            compteurMaintenance.push(maintenanceObject);
            const itemCompteur = compteurMaintenance.findIndex(
              (e) => e.name === smallName
            );
            compteurMaintenance[itemCompteur][`${year}`]
              ? (compteurMaintenance[itemCompteur][`${year}`] += 1)
              : (compteurMaintenance[itemCompteur][`${year}`] = 1);
          }
        }
      });

      // hydro chart
      let compteurHydro = [
        {
          trimestre: "Trimestre 1",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
        {
          trimestre: "Trimestre 2",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
        {
          trimestre: "Trimestre 3",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
        {
          trimestre: "Trimestre 4",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
      ];
      let compteurOil = [
        {
          trimestre: "Trimestre 1",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
        {
          trimestre: "Trimestre 2",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
        {
          trimestre: "Trimestre 3",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
        {
          trimestre: "Trimestre 4",
          2021: {
            embarquee: 0,
            debarquee: 0,
          },
          2022: {
            embarquee: 0,
            debarquee: 0,
          },
          2023: {
            embarquee: 0,
            debarquee: 0,
          },
        },
      ];
      hydroDocs.forEach((el, i) => {
        const data = el.data();
        let isOil = true;
        if (data.hydrocarbure === "Gazole" || data.hydrocarbure === "Essence") {
          isOil = false;
        }
        const currentYear = data.date.split("/")[0];
        const trimestreFilter = (year, hydroType) => {
          if (
            Date.parse(data.date) >= Date.parse(`${year}/01/21`) &&
            Date.parse(data.date) <= Date.parse(`${year}/03/31`)
          ) {
            hydroType[0][currentYear]
              ? data.quantityDown
                ? (hydroType[0][currentYear] =
                    hydroType[0][currentYear] +
                    (parseInt(data.quantity) - parseInt(data.quantityDown)))
                : (hydroType[0][currentYear] =
                    hydroType[0][currentYear] + parseInt(data.quantity))
              : (hydroType[0][currentYear] = 1);
            return;
          }
          if (
            Date.parse(data.date) >= Date.parse(`${year}/03/01`) &&
            Date.parse(data.date) <= Date.parse(`${year}/06/30`)
          ) {
            hydroType[1][currentYear]
              ? data.quantityDown
                ? (hydroType[1][currentYear] =
                    hydroType[1][currentYear] +
                    (parseInt(data.quantity) - parseInt(data.quantityDown)))
                : (hydroType[1][currentYear] =
                    hydroType[1][currentYear] + parseInt(data.quantity))
              : (hydroType[1][currentYear] = 1);
            return;
          }
          if (
            Date.parse(data.date) >= Date.parse(`${year}/07/01`) &&
            Date.parse(data.date) <= Date.parse(`${year}/09/30`)
          ) {
            hydroType[2][currentYear]
              ? data.quantityDown
                ? (hydroType[2][currentYear] =
                    hydroType[2][currentYear] +
                    (parseInt(data.quantity) - parseInt(data.quantityDown)))
                : (hydroType[2][currentYear] =
                    hydroType[2][currentYear] + parseInt(data.quantity))
              : (hydroType[2][currentYear] = 1);
            return;
          }
          if (
            Date.parse(data.date) >= Date.parse(`${year}/10/01`) &&
            Date.parse(data.date) <= Date.parse(`${year}/12/31`)
          ) {
            hydroType[3][currentYear]
              ? data.quantityDown
                ? (hydroType[3][currentYear] =
                    hydroType[3][currentYear] +
                    (parseInt(data.quantity) - parseInt(data.quantityDown)))
                : (hydroType[3][currentYear] =
                    hydroType[3][currentYear] + parseInt(data.quantity))
              : (hydroType[3][currentYear] = 1);
            return;
          }
        };

        const trimestreFilterHydro = (year, hydroType) => {
          if (
            Date.parse(data.date) >= Date.parse(`${year}/01/21`) &&
            Date.parse(data.date) <= Date.parse(`${year}/03/31`)
          ) {
            hydroType[0][currentYear] &&
              (hydroType[0][currentYear] = {
                embarquee: data.quantity
                  ? hydroType[0][currentYear].embarquee +
                    parseInt(data.quantity)
                  : hydroType[0][currentYear].embarquee,
                debarquee: data.quantityDown
                  ? hydroType[0][currentYear].debarquee +
                    parseInt(data.quantityDown)
                  : hydroType[0][currentYear].debarquee,
              });
            return;
          }
          if (
            Date.parse(data.date) >= Date.parse(`${year}/03/01`) &&
            Date.parse(data.date) <= Date.parse(`${year}/06/30`)
          ) {
            hydroType[1][currentYear] &&
              (hydroType[1][currentYear] = {
                embarquee: data.quantity
                  ? hydroType[1][currentYear].embarquee +
                    parseInt(data.quantity)
                  : hydroType[1][currentYear].embarquee,
                debarquee: data.quantityDown
                  ? hydroType[1][currentYear].debarquee +
                    parseInt(data.quantityDown)
                  : hydroType[1][currentYear].debarquee,
              });
            return;
          }
          if (
            Date.parse(data.date) >= Date.parse(`${year}/07/01`) &&
            Date.parse(data.date) <= Date.parse(`${year}/09/30`)
          ) {
            hydroType[2][currentYear] &&
              (hydroType[2][currentYear] = {
                embarquee: data.quantity
                  ? hydroType[2][currentYear].embarquee +
                    parseInt(data.quantity)
                  : hydroType[2][currentYear].embarquee,
                debarquee: data.quantityDown
                  ? hydroType[2][currentYear].debarquee +
                    parseInt(data.quantityDown)
                  : hydroType[2][currentYear].debarquee,
              });
            return;
          }
          if (
            Date.parse(data.date) >= Date.parse(`${year}/10/01`) &&
            Date.parse(data.date) <= Date.parse(`${year}/12/31`)
          ) {
            hydroType[3][currentYear] &&
              (hydroType[3][currentYear] = {
                embarquee: data.quantity
                  ? hydroType[3][currentYear].embarquee +
                    parseInt(data.quantity)
                  : hydroType[3][currentYear].embarquee,
                debarquee: data.quantityDown
                  ? hydroType[3][currentYear].debarquee +
                    parseInt(data.quantityDown)
                  : hydroType[3][currentYear].debarquee,
              });
            return;
          }
        };

        if (isOil) {
          trimestreFilterHydro(currentYear, compteurOil);
          return;
        } else {
          trimestreFilterHydro(currentYear, compteurHydro);
          return;
        }
      });

      let tmpHydroNegative = [
        {
          trimestre: "Trimestre 1",
          2021: {
            embarquee: compteurHydro[0][2021].embarquee,
            debarquee: compteurHydro[0][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurHydro[0][2022].embarquee,
            debarquee: compteurHydro[0][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurHydro[0][2023].embarquee,
            debarquee: compteurHydro[0][2023].debarquee * -1,
          },
        },
        {
          trimestre: "Trimestre 2",
          2021: {
            embarquee: compteurHydro[1][2021].embarquee,
            debarquee: compteurHydro[1][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurHydro[1][2022].embarquee,
            debarquee: compteurHydro[1][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurHydro[1][2023].embarquee,
            debarquee: compteurHydro[1][2023].debarquee * -1,
          },
        },
        {
          trimestre: "Trimestre 3",
          2021: {
            embarquee: compteurHydro[2][2021].embarquee,
            debarquee: compteurHydro[2][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurHydro[2][2022].embarquee,
            debarquee: compteurHydro[2][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurHydro[2][2023].embarquee,
            debarquee: compteurHydro[2][2023].debarquee * -1,
          },
        },
        {
          trimestre: "Trimestre 4",
          2021: {
            embarquee: compteurHydro[3][2021].embarquee,
            debarquee: compteurHydro[3][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurHydro[3][2022].embarquee,
            debarquee: compteurHydro[3][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurHydro[3][2023].embarquee,
            debarquee: compteurHydro[3][2023].debarquee * -1,
          },
        },
      ];

      let tmpOilNegative = [
        {
          trimestre: "Trimestre 1",
          2021: {
            embarquee: compteurOil[0][2021].embarquee,
            debarquee: compteurOil[0][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurOil[0][2022].embarquee,
            debarquee: compteurOil[0][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurOil[0][2023].embarquee,
            debarquee: compteurOil[0][2023].debarquee * -1,
          },
        },
        {
          trimestre: "Trimestre 2",
          2021: {
            embarquee: compteurOil[1][2021].embarquee,
            debarquee: compteurOil[1][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurOil[1][2022].embarquee,
            debarquee: compteurOil[1][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurOil[1][2023].embarquee,
            debarquee: compteurOil[1][2023].debarquee * -1,
          },
        },
        {
          trimestre: "Trimestre 3",
          2021: {
            embarquee: compteurOil[2][2021].embarquee,
            debarquee: compteurOil[2][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurOil[2][2022].embarquee,
            debarquee: compteurOil[2][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurOil[2][2023].embarquee,
            debarquee: compteurOil[2][2023].debarquee * -1,
          },
        },
        {
          trimestre: "Trimestre 4",
          2021: {
            embarquee: compteurOil[3][2021].embarquee,
            debarquee: compteurOil[3][2021].debarquee * -1,
          },
          2022: {
            embarquee: compteurOil[3][2022].embarquee,
            debarquee: compteurOil[3][2022].debarquee * -1,
          },
          2023: {
            embarquee: compteurOil[3][2023].embarquee,
            debarquee: compteurOil[3][2023].debarquee * -1,
          },
        },
      ];

      setHydroCharts(tmpHydroNegative);
      setOilCharts(tmpOilNegative);
      setCahierCharts(compteur);
      setMaintenanceCharts(compteurMaintenance);

      const chartElement = printRef.current;
      const canvas = await html2canvas(chartElement);
      const chartImg = canvas.toDataURL("image/png");
      const chartElementMaintenance = printRefMaintenance.current;
      const canvasMaintenance = await html2canvas(chartElementMaintenance);
      const chartImgMaintenance = canvasMaintenance.toDataURL("image/png");
      const chartElementHydro = printRefHydro.current;
      const canvasHydro = await html2canvas(chartElementHydro);
      const chartImgHydro = canvasHydro.toDataURL("image/png");
      const chartElementOil = printRefOil.current;
      const canvasOil = await html2canvas(chartElementOil);
      const chartImgOil = canvasOil.toDataURL("image/png");

      setPdf({
        cahier: cahierDocs.docs.map((e) => e.data()),
        maintenance: maintenanceDocs.docs.map((e) => e.data()),
        hydro: hydroDocs.docs.map((e) => e.data()),
        ship: ship,
        startDate: startDate,
        endDate: endDate,
        cahierCharts: chartImg || null,
        maintenanceCharts: chartImgMaintenance || null,
        hydroCharts: chartImgHydro || null,
        oilCharts: chartImgOil || null,
      });

      await firebase.db.collection("rapports").doc(id).update({
        status: "accepted",
      });
      enqueueSnackbar(
        "Génération du rapport terminée, le téléchargement va commencer...",
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar("Une erreur est survenue", { variant: "error" });
      setLoading(false);
    }
  };

  const downloadBlob = async (blob) => {
    if (!isBlobLoading) {
      console.log(blob);
      isBlobLoading = true;
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Rapport ${pdf.ship.name}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      setPdf(null);
      setLoading(false);
      setTimeout(() => {
        isBlobLoading = false;
      }, 5000);
    }
  };

  const getData = () => {
    setLines([]);
    firebase.db
      .collection("rapports")
      .orderBy("createdAt", "desc")
      .onSnapshot(async (values) => {
        let tmp = await Promise.all(
          values.docs.map(async (doc) => {
            const ship = await firebase.db
              .collection("Ships")
              .doc(doc.data().ship.id)
              .get();
            console.log(doc.data().ship.id);

            return {
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
              id: doc.id,
              shipName: ship.data().name,
              shipId: ship.id,
              ship: ship.data(),
            };
          })
        );
        console.log(tmp);
        setLines(tmp);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date de création",
      disableClickEventBubbling: true,
      width: 200,
      valueGetter: (params) => {
        if (params.row.createdAt) {
          return moment(params.row.createdAt).format("DD/MM/YYYY");
        }
        return null;
      },
    },
    {
      field: "shipName",
      headerName: "Navire",
      disableClickEventBubbling: true,
      width: 200,
    },
    {
      field: "period",
      headerName: "Période",
      disableClickEventBubbling: true,
      width: 200,
      valueGetter: (params) => {
        return `${moment(params.row.start).format("DD/MM/YYYY")} - ${moment(
          params.row.end
        ).format("DD/MM/YYYY")}`;
      },
    },
    {
      field: "status",
      headerName: "Statut",
      disableClickEventBubbling: true,
      width: 200,
      valueGetter: (params) => {
        let status = params.row.status;

        switch (status) {
          case "pending":
            status = "En attente";
            break;
          case "rejected":
            status = "Refusé";
            break;
          case "accepted":
            status = "Généré";
            break;
          default:
            break;
        }

        return status;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      disableClickEventBubbling: true,
      width: 400,
      renderCell: (params) => {
        return (
          <>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => handleAccept(params.row)}
            >
              Accepter
            </Button>
            <Button
              color="secondary"
              onClick={() => handleRefus(params.row.id)}
            >
              Refuser
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid className={classes.grid}>
          <Typography
            component="h1"
            variant="h1"
            style={{
              fontFamily: "MostraThree",
              fontSize: 50,
              color: "#00638F",
              textDecoration: "none",
              margin: "50px 0",
              marginLeft: "15%",
            }}
          >
            MAINT & SEA
          </Typography>
          <Container>
            <Datatable title={"Rapports"} columns={columns} rows={lines} />
          </Container>
        </Grid>
      </Grid>
      {pdf && (
        <BlobProvider document={<Rapport {...pdf} />} fileName="rapport.pdf">
          {({ blob, url, loading, error }) => {
            blob && downloadBlob(blob);
            return null;
          }}
        </BlobProvider>
      )}
      <div
        ref={printRef}
        style={{
          position: "absolute",
          top: -2000,
          left: 0,
          width: 1460,
          height: 710,
          backgroundColor: "white",
        }}
      >
        <BarChart
          width={1450}
          height={700}
          data={cahierCharts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            style={{
              fontSize: 12,
            }}
          />
          <YAxis
            style={{
              fontSize: "2rem",
            }}
          />
          <Tooltip />
          <Legend iconSize={36} />
          <Bar dataKey="Nombre" fill="#8884d8" isAnimationActive={false} />
        </BarChart>
      </div>
      <div
        ref={printRefMaintenance}
        style={{
          position: "absolute",
          top: -2000,
          left: 0,
          width: 1460,
          height: 710,
          backgroundColor: "white",
          zIndex: 1000,
        }}
      >
        <BarChart
          width={1450}
          height={700}
          data={maintenanceCharts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            style={{
              fontSize: 12,
            }}
          />
          <YAxis
            style={{
              fontSize: "2rem",
            }}
          />
          <Tooltip />
          <Legend iconSize={36} />
          {yearsBetweenSelected.map((el) => {
            return (
              <Bar
                dataKey={el.year}
                fill={`#${el.color}`}
                isAnimationActive={false}
              />
            );
          })}
        </BarChart>
      </div>
      <div
        ref={printRefHydro}
        style={{
          position: "absolute",
          top: -2000,
          left: 0,
          width: 1460,
          height: 710,
          backgroundColor: "white",
          zIndex: 1000,
        }}
      >
        <BarChart
          width={1450}
          height={700}
          data={hydroCharts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="trimestre"
            style={{
              fontSize: 12,
            }}
          />
          <YAxis
            style={{
              fontSize: "2rem",
            }}
          />
          <Tooltip />
          <Legend iconSize={36} />
          {yearsBetweenSelected.map((el, i) => {
            return (
              <Bar
                dataKey={`${el.year}.embarquee`}
                fill={`#${el.color}`}
                isAnimationActive={false}
              />
            );
          })}
          {yearsBetweenSelected.map((el, i) => {
            return (
              <Bar
                dataKey={`${el.year}.debarquee`}
                fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                isAnimationActive={false}
              />
            );
          })}
        </BarChart>
      </div>
      <div
        ref={printRefOil}
        style={{
          position: "absolute",
          top: -2000,
          left: 0,
          width: 1460,
          height: 710,
          backgroundColor: "white",
          zIndex: 1000,
        }}
      >
        <BarChart
          width={1450}
          height={700}
          data={oilCharts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="trimestre"
            style={{
              fontSize: 12,
            }}
          />
          <YAxis
            style={{
              fontSize: "2rem",
            }}
          />
          <Tooltip />
          <Legend iconSize={36} />
          {yearsBetweenSelected.map((el, i) => {
            return (
              <Bar
                dataKey={`${el.year}.embarquee`}
                ori
                fill={`#${el.color}`}
                isAnimationActive={false}
              />
            );
          })}
          {yearsBetweenSelected.map((el, i) => {
            return (
              <Bar
                dataKey={`${el.year}.debarquee`}
                ori
                fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                isAnimationActive={false}
              />
            );
          })}
        </BarChart>
      </div>
    </>
  );
}
