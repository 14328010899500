import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, useParams, Link } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InputSelect from "../Inputs/InputSelect";
import { hashSync } from "bcryptjs";
import { ArrowBack, Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Chip } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    borderRadius: 25,
  },
  inputSemi: {
    width: "48%",
  },
  inputsLine1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  grid: {
    width: "100%",
  },
  card: {
    backgroundColor: "#ffffff",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
    boxShadow: "0 0 0.75rem rgba(0, 0, 0, .05)",
    marginTop: 5,
    padding: 20,
  },
  brevet: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
    width: "100%",
  },
  backButton: {
    marginLeft: 20,
    marginTop: 20,
  },
}));

export default function Sailor({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams();
  const history = useHistory();
  const [sailor, setSailor] = useState({});
  const [availableBrevets, setAvailableBrevets] = useState([]);
  const [brevets, setBrevets] = useState([]);
  const [baseDates, setBaseDates] = useState({});
  useEffect(() => {
    firebase.db
      .collection("Sailors")
      .doc(id)
      .onSnapshot((doc) => {
        setSailor({ ...doc.data(), ref: doc.ref });
        if (doc.data().brevets) {
          setBrevets(doc.data().brevets.map((el) => el.name));
          let tmp = {};
          doc.data().brevets.forEach((el) => {
            tmp[el.name] = el.date;
          });
          setBaseDates(tmp);
        }
      });
    firebase.db.collection("Brevets").onSnapshot((docs) => {
      let tmp = [];
      docs.forEach((doc) => {
        tmp.push(doc.data().name);
      });
      setAvailableBrevets(tmp);
    });
  }, []);

  const handleSubmitName = (values) => {
    console.log(values);
    sailor.ref.update(values).then(() => {
      enqueueSnackbar("Nom modifié", { variant: "success" });
    });
  };
  const handleSubmitPassword = (values) => {
    const hash = hashSync(values.pass);
    if (values.pass !== values.passconf) {
      enqueueSnackbar("Les mots de passes ne sont pas identiques", {
        variant: "error",
      });
      return;
    }
    sailor.ref
      .update({ password: hash })
      .then(() => {
        enqueueSnackbar("Mot de passe modifié", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la modification du mot passe", {
          variant: "error",
        });
      });
  };
  const handleSubmitBrevet = (values) => {
    sailor.ref
      .update({
        brevets: brevets.map((el) => {
          return {
            name: el,
            date: values.date[el],
          };
        }),
      })
      .then(() => {
        enqueueSnackbar("Qualifications brevets mises à jour", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la modification", {
          variant: "error",
        });
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid className={classes.grid}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            margin: "50px 0",
            marginLeft: "15%",
          }}
        >
          MAINT & SEA
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ArrowBack color="primary" />}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <div
          style={{
            padding: "5%",
          }}
        >
          <div className={classes.card}>
            <Avatar />
            <h2 style={{ fontWeight: 400, color: "#00638F" }}>{id}</h2>
            <FinalForm
              onSubmit={handleSubmitName}
              initialValues={{ name: sailor.name }}
            >
              <Typography>Modifier le nom du marin</Typography>
              <InputText name="name" label="Nom du marin" required />
              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                type="submit"
              >
                Modifier
              </Button>
            </FinalForm>
            <FinalForm onSubmit={handleSubmitPassword}>
              <Typography>Modifier le mot de passe du marin</Typography>

              <InputText
                name="pass"
                label="Nouveau mot de passe"
                type="password"
                required
              />
              <InputText
                name="passconf"
                label="Confirmez le nouveau mot de passe"
                type="password"
                required
              />
              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                type="submit"
              >
                Modifier
              </Button>
            </FinalForm>
            <FinalForm
              onSubmit={handleSubmitBrevet}
              initialValues={{ date: baseDates }}
            >
              <Typography>Qualification brevets</Typography>
              <Autocomplete
                options={availableBrevets}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginTop: 15 }}
                  />
                )}
                selectOnFocus
                autoHighlight
                handleHomeEndKeys
                multiple
                disableCloseOnSelect
                value={brevets}
                onChange={(event, newValue) => {
                  setBrevets(newValue);
                }}
                renderTags={(brevets, getTagProps) =>
                  brevets.map((description, index) => (
                    <Chip label={description} {...getTagProps({ index })} />
                  ))
                }
              />
              {brevets.length > 0 && (
                <>
                  <Typography>Dates réévaluation</Typography>
                  {brevets.map((brevet) => {
                    return (
                      <>
                        <Typography
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            marginTop: 10,

                            marginLeft: 20,
                            marginBottom: -20,
                          }}
                        >
                          {brevet}
                        </Typography>
                        <InputText type="date" name={`date[${brevet}]`} />
                      </>
                    );
                  })}
                </>
              )}

              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                type="submit"
              >
                Modifier
              </Button>
            </FinalForm>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
