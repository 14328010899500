import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyD3PFIpCNUCHanrus6QgjQeGw91kpbpu7s",

  authDomain: "prospection-commerciale-10ace.firebaseapp.com",

  projectId: "prospection-commerciale-10ace",

  storageBucket: "prospection-commerciale-10ace.appspot.com",

  messagingSenderId: "556341352320",

  appId: "1:556341352320:web:b582b25e1e1dec297e1365",
};

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    } else {
      app.app(); // if already initialized, use that one
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // *** User API ***
  user = (uid) => this.db.ref(`Users/${uid}`);
}
export default Firebase;
