import React from "react";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  makeStyles,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteIcon from "@material-ui/icons/Delete";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import moment from "moment";
import { useSnackbar } from "notistack";
import { HistorySharp, Visibility } from "@material-ui/icons";
import HistoryIcon from "@material-ui/icons/History";

export default function Checklist({ firebase, shipId }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [details, setDetails] = useState("");
  const [items, setItems] = useState(false);
  const [historys, setHistorys] = useState("");
  const [history, setHistory] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [openItems, setOpenItems] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toggleItem = (i) => {
    if (openItems.includes(i)) {
      setOpenItems((prev) => prev.filter((el) => el != i));
      setItems(false);
    } else {
      setOpenItems((prev) => [i]);
      setItems(true);
    }
  };

  const fetchEvents = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("checklists")
      .orderBy("name")
      .get()
      .then((snapshot) => {
        let tmp = [];
        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        setEvents(tmp);
      });
  };

  const [events, setEvents] = useState();
  useEffect(() => {
    fetchEvents();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const withFilters = (item) => {
    if (search == "") {
      return item;
    }
    if (item.name?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.validatedAt?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    setDetails(false);
    setOpenItems([]);
    setHistory(false);
  };

  const handleDelete = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("checklists")
      .doc(deleteEvent.id)
      .delete()
      .then(() => {
        enqueueSnackbar("Évènement supprimé !", { variant: "success" });
        fetchEvents();
        setDeleteEvent(null);
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la suppression", { variant: "error" });
      });
  };

  const fetchHistory = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("checklists")
      .doc(history.id)
      .collection("historique")
      .orderBy("validatedAt", "desc")
      .get()
      .then((snapshot) => {
        let tmp = [];
        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data() });
        });
        setHistorys(tmp);
      });
  };

  useEffect(() => {
    if (history) {
      fetchHistory();
    }
  }, [history]);

  console.log(items);

  return (
    <Container>
      <div className={classes.SearchAndCardWrapper}>
        <TextField
          className={classes.searchbar}
          variant="outlined"
          placeholder="Recherche par nom"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  style={{ width: 25, height: 25 }}
                  src={require("../../Icons/search.png")}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Link
          to={"/" + shipId + "/addChecklist"}
          style={{ textDecoration: "none" }}
          className={classes.card}
        >
          <svg
            style={{ fontSize: 45 }}
            class="MuiSvgIcon-root jss174"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill="#329646"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
            ></path>
          </svg>
        </Link>
      </div>

      <TableContainer>
        <Table aria-label="simple table" className={classes.head}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.col}>NOM</TableCell>

              <TableCell className={classes.col}>VOIR EN COURS</TableCell>
              <TableCell className={classes.col}>HISTORIQUE</TableCell>
              <TableCell className={classes.col}>SUPPRIMER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events
              ?.filter((item) => withFilters(item))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell>{row.name}</TableCell>

                  <TableCell>
                    <Visibility
                      style={{ cursor: "pointer" }}
                      onClick={() => setDetails(row)}
                    />
                  </TableCell>
                  <TableCell>
                    <HistoryIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setHistory(row)}
                    />
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setDeleteEvent(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={events?.filter((item) => withFilters(item)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page"
      />

      <Dialog open={history} onClose={handleClose} fullWidth>
        <DialogTitle>Historique </DialogTitle>
        <DialogContent>
          <DialogContent>
            <div>
              {historys &&
                historys?.map((row, index) => (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {row.validatedAt
                        ? "Enregistrée le " +
                          moment(row.validatedAt.toDate()).format(
                            "DD/MM/YYYY hh:mm:ss"
                          ) +
                          " par " +
                          row.validatedBy?.id
                        : ""}
                      {items && openItems[0] === row.items ? (
                        <KeyboardArrowUpIcon
                          onClick={() => toggleItem(row.items)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          onClick={() => toggleItem(row.items)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    <ul>
                      {openItems[0] === row.items &&
                        row.items?.map((item) => (
                          <li
                            style={{
                              display: "flex",
                              alignItems: "center",
                              listStyle: "none",
                            }}
                          >
                            {item.checked ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )}
                            <p style={{ marginLeft: "5px" }}>{item.title}</p>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={details} onClose={handleClose} fullWidth>
        <DialogTitle>Détails</DialogTitle>
        <DialogContent style={{ padding: "16px 28px" }}>
          <div>
            <ul style={{ margin: "0" }}>
              {details.items?.map((item) => (
                <li
                  style={{
                    listStyle: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.checked ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}{" "}
                  <p style={{ marginLeft: "5px" }}>{item.title}</p>
                </li>
              ))}
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteEvent}
        onClose={() => setDeleteEvent(false)}
        fullWidth
      >
        <DialogTitle>Attention - {deleteEvent?.name}</DialogTitle>
        <DialogContent>
          <Typography>
            Voulez vous vraiment supprimer cette checklist ? Cette action est
            irréversible
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteEvent(false)}>Annuler</Button>

          <Button onClick={handleDelete}>Supprimer la checklist</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  col: {
    color: "#00638F",
    borderBottomWidth: 0,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#DCEFF6",
    },
  },
  searchbar: {
    display: "flex",
    borderRadius: 50,
    width: "60%",
    margin: "10px auto",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    padding: "0 15px",
    textAlign: "center",
  },
  SearchAndCardWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  checklist: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
    icon: {
      color: theme.palette.primary.main,
    },
  },
}));
