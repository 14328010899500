import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import SignInSide from "./Pages/Login";
import FirebaseContext from "./FirebaseContext";
import Sailors from "./Pages/Sailors";
import Home from "./Pages/Home";
import Ship from "./Pages/Ship";
import { loadStripe } from "@stripe/stripe-js";
import AppContainer from "./Containers/AppContainer";
import PrivateRoute from "./Utils/PrivateRoute";
import AddMaintenance from "./Pages/AddMaintenance";
import AddCahier from "./Pages/AddCahier";
import AddChecklist from "./Pages/AddChecklist";
import UpdateMaintenance from "./Pages/UpdateMaintenance";
import Storage from "./Pages/HomeComponents/Storage";
import Horodatage from "./Pages/HomeComponents/Horodatage";
import Sailor from "./Pages/Sailor";
import UpdateHydrocarbure from "./Pages/UpdateHydrocarbure";
import AddHydrocarbure from "./Pages/AddHydrocarbure";
import AddPartenaire from "./Pages/AddPartenaire";
import Rapports from "./Pages/Rapports";
const stripePromise = loadStripe(
  "pk_live_51IXVUjIDhJTsGLwo3sQOAMxN0YuLDMHhWF9JnBM5l8nKTOrtPjmb0MEJI1nBaM5RymGtIGfg5fXP5G4J9tT0rBBN00jhUT8qPD"
);

export default function Routes() {
  const [cookies, setCookie] = useCookies();

  return (
    <Router>
      <Switch>
        {/* LOGOUT ROUTES */}
        <Route path="/login">
          <FirebaseContext.Consumer>
            {(firebase) => <SignInSide firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        <PrivateRoute path="/">
          <FirebaseContext.Consumer>
            {(firebase) => (
              <AppContainer firebase={firebase}>
                <Switch>
                  <PrivateRoute path="/:shipId/sailors">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Sailors firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute exact path="/ship/:shipId/sailor/:id">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Sailor firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/ship/:shipId">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Ship firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/addCahier">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddCahier firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/storage">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Storage firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/horodatage">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Horodatage firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/addMaintenance">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddMaintenance firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/maintenance/:eventId">
                    <FirebaseContext.Consumer>
                      {(firebase) => <UpdateMaintenance firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/addHydrocarbure">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddHydrocarbure firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/hydrocarbure/:eventId">
                    <FirebaseContext.Consumer>
                      {(firebase) => <UpdateHydrocarbure firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/addChecklist">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddChecklist firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/rapports">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Rapports firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/partenaires">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddPartenaire firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute exact path="/:collection">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Home firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute exact path="/">
                    <Redirect to="/Armateurs" />
                  </PrivateRoute>
                </Switch>
              </AppContainer>
            )}
          </FirebaseContext.Consumer>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
