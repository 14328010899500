import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useCookies } from "react-cookie";
import { Grid, Paper, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Data from "../newData.csv";
import Papa from "papaparse";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
    background: "rgb(17,175,211)",
    background: "#4b4b4b",
    height: "100vh",
    paddingTop: 10,
  },
  fullList: {
    width: "auto",
  },
  categorieTitle: {
    fontWeight: 400,
    fontSize: 40,
    textAlign: "left",
    color: "#fff",
    marginTop: 0,
    marginLeft: 20,
    fontFamily: "Poppins",
  },
  lineName: {
    color: "#fff",
    marginLeft: 15,
    fontWeight: 400,
    fontSize: 17,
    fontFamily: "Poppins",
    textDecoration: "none",
  },
  itemContainer: {
    paddingBottom: 29,
    marginLeft: 5,
    paddingTop: 28,
  },
  itemContainerSelected: {
    backgroundImage: "url('images/menu.png')",
    paddingBottom: 29,
    marginLeft: 5,
    width: 300,
    paddingTop: 28,
    backgroundSize: "cover",
  },
  lineName1: {
    color: "#0071BC",
    marginLeft: 15,
    fontWeight: 400,
    fontSize: 17,
    fontFamily: "Poppins",
    textDecoration: "none",
  },
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(0, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#388ADC",
  },
  inputSemi: {
    width: "48%",
  },
  inputsLine1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  grid: {
    height: "100vh",
    width: 350,
  },
  logoutBtn: {
    textDecoration: "none",
    color: "#00638F",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 5,
    marginTop: 25,
    "&:hover": {
      color: "#b21d1d",
    },
  },
}));

const collections = [
  {
    collection: "users",
    label: "Utilisateurs",
  },
  { collection: "devis", label: "Devis" },
  { collection: "produits", label: "Produits" },
  { collection: "prosp_horsqr", label: "Prosp. hors QR" },
  { collection: "qrcode", label: "QR code" },
  { collection: "rendez_vous", label: "Rendez-vous" },
  { collection: "secteurActivite", label: "Secteur d'activité" },
];

export default function TemporaryDrawer({ firebase, open, switchMenu }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const [redirect, setRedirect] = useState(null);
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [shipId, setShipId] = useState();
  const history = useHistory();

  const [rapports, setRapports] = useState(0);

  useEffect(() => {
    console.log(location.pathname);
    setState({ ...state, left: open });
  }, [open]);

  const shipsRef = firebase.db.collection("Ships");
  const [ships, setShips] = useState([]);
  const [item, setItem] = useState([]);

  useEffect(() => {
    firebase.db
      .collection("rapports")
      .where("status", "==", "pending")
      .onSnapshot((snap) => setRapports(snap.size));
  }, []);

  const fetchColl = async () => {
    // firebase.db
    //   .collection("DescriptionsCahier2")
    //   .get()
    //   .then((docs) => {
    //     docs.forEach((doc) => {
    //       firebase.db
    //         .collection("DescriptionsCahier2")
    //         .doc(doc.id)
    //         .update({
    //           type: doc.type || "tous",
    //           size: doc.size || "tous",
    //         })
    //         .then(() => console.log("success"))
    //         .catch((err) => console.log("error ", err));
    //     });
    //   });

    // return;
    item.map((el, i) => {
      firebase.db
        .collection("DescriptionsReglementaire")
        .add({
          label: el.label,
          category: el.category,
          type: el.type,
          size: el.size,
        })
        .then(() => {
          console.log("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    Papa.parse(Data, {
      download: true,
      header: true,
      dynamicTyping: true,
      complete: function (results) {
        setItem(results.data);
        console.log(results.data);
      },
    });
  }, []);

  const logout = async () => {
    removeCookie("userToken");
    removeCookie("email");
    await firebase.auth.signOut();
    history.replace("/login");
  };

  const clickBro = (collection) => {
    setShipId(collection);
  };

  const sideList = (side) => (
    <Grid className={classes.grid} component={Paper} elevation={8}>
      <div className={classes.paper} style={{ width: "90%" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#388ADC",
              fontSize: 40,
            }}
          >
            Collections
          </Typography>
        </div>
        <div
          style={{
            overflowY: "scroll",
            width: "100%",
            padding: 10,
            height: "70vh",
          }}
        >
          <>
            {/* {location.pathname === "/rapports" ? (
              <Link to={"/rapports"} style={{ textDecoration: "none" }}>
                <div
                  style={{
                    border: "2px solid #B5B5B5",
                    backgroundColor: "#00638F",
                    marginTop: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h5"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 25,
                      color: "#fff",
                      textAlign: "center",
                      textDecoration: "none",
                    }}
                  >
                    Rapports ({rapports})
                  </Typography>
                </div>
              </Link>
            ) : (
              <Link to={"/rapports"} style={{ textDecoration: "none" }}>
                <div
                  style={{
                    border: "2px solid #B5B5B5",
                    marginTop: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h5"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 25,
                      color: "#B5B5B5",
                      textDecoration: "none",
                      textAlign: "center",
                    }}
                  >
                    Rapports ({rapports})
                  </Typography>
                </div>
              </Link>
            )} */}
          </>
          {collections.map((collection) => {
            return (
              <>
                {location.pathname === "/" + collection.collection ? (
                  <Link
                    to={"/" + collection.collection}
                    style={{ textDecoration: "none" }}
                    onClick={() => clickBro(collection.collection)}
                  >
                    <div
                      style={{
                        border: "2px solid #B5B5B5",
                        backgroundColor: "#388ADC",
                        marginTop: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: 50,
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        component="h3"
                        variant="h5"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 25,
                          color: "#fff",
                          textAlign: "center",
                          textDecoration: "none",
                        }}
                      >
                        {collection.label}
                      </Typography>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={"/" + collection.collection}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      clickBro(collection.collection);
                    }}
                  >
                    <div
                      style={{
                        border: "2px solid #B5B5B5",
                        marginTop: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: 50,
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        component="h3"
                        variant="h5"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 25,
                          color: "#B5B5B5",
                          textDecoration: "none",
                          textAlign: "center",
                        }}
                      >
                        {collection.label}
                      </Typography>
                    </div>
                  </Link>
                )}
              </>
            );
          })}
        </div>

        <Link onClick={logout} className={classes.logoutBtn}>
          <ExitToAppIcon style={{ marginRight: 5 }} /> Déconnexion
        </Link>
        {/*    <button onClick={() => fetchColl()}>hello</button>
        <button onClick={() => console.log("l341 ", item)}>hello22</button> */}

        <Grid container>
          <Grid item xs></Grid>
          <Grid item></Grid>
        </Grid>
      </div>
    </Grid>
  );
  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      {sideList("left")}
    </>
  );
}
