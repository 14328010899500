import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  DataGrid,
  frFR,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  container: {
    maxHeight: 440,
  },
  datatableTitle: {
    color: theme.palette.primary.main + "!important",
    fontSize: 25,
    fontWeight: 600,
  },
  datatableHead: {
    padding: 10,
    paddingLeft: 20,
    display: "flex",
  },
  headTitles: {
    color: theme.palette.primary.main,
  },
}));
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarExport
        csvOptions={{
          delimiter: ";",
          fileName: "maint&sea-" + moment().format("YYYY-MM-DD"),
        }}
      /> */}
    </GridToolbarContainer>
  );
}
export default function Datatable({
  AddButton,
  title,
  columns = [],
  rows = [],
}) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (params) => {
    setRowsPerPage(params.pageSize);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.datatableHead}>
        <div className={classes.navbar}>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#388ADC",
              fontSize: 30,
              marginBottom: 10,
            }}
          >
            {title}
          </Typography>
        </div>
        {AddButton && <AddButton></AddButton>}
      </div>

      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={rowsPerPage}
        pagination
        onPageSizeChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 100]}
        localeText={frFR.props.MuiDataGrid.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </Paper>
  );
}
