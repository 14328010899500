import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  makeStyles,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useSnackbar } from "notistack";
import { CloudDownload, Delete, Edit, Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";
import FinalForm from "../../Utils/FinalForm";
import { useCookies } from "react-cookie";

const Storage = ({ firebase, shipId }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [deleteFile, setDeleteFile] = useState(false);
  const [renameFile, setRenameFile] = useState(null);
  const [upload, setUpload] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [description, setDescription] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [link, setLink] = useState();
  const [cookies, setCookie] = useCookies();

  const fetchEvents = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("files")
      .orderBy("date")
      .get()
      .then((snapshot) => {
        let tmp = [];
        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        setEvents(tmp);
      });
  };

  const handleUpload = () => {
    if (uploadFile && link) {
      enqueueSnackbar(
        "Vous ne pouvez pas enregistrer un lien et un fichier en même temps.",
        { variant: "error" }
      );
      return;
    }
    if (link && link.length > 0) {
      firebase.db
        .collection("Ships")
        .doc(shipId)
        .collection("files")
        .add({
          date: new Date(),
          name: link,
          description: description || "",
          path: "easyLink",
        })
        .then(() => {
          fetchEvents();
          enqueueSnackbar("Lien enregistré avec succès !", {
            variant: "success",
          });
          setUpload(false);
          setUploadFile(null);
          setFilename(false);
        });
      return;
    }
    if (!uploadFile) {
      return;
    }
    enqueueSnackbar("Chargement du fichier...", { variant: "info" });
    const fileRef = firebase.storage
      .ref()
      .child(`${cookies.userToken}/${shipId}/${uploadFile.name}`);
    fileRef.put(uploadFile).then((snapshot) => {
      firebase.db
        .collection("Ships")
        .doc(shipId)
        .collection("files")
        .add({
          date: new Date(),
          name: filename || uploadFile.name,
          description: description || "",
          path: fileRef.fullPath,
        })
        .then(() => {
          fetchEvents();
          enqueueSnackbar("Fichier enregistré !", { variant: "success" });
          setUpload(false);
          setUploadFile(null);
          setFilename(false);
          // setDescription(false);
        });
    });
  };
  const handleRename = () => {
    if (!filename) {
      enqueueSnackbar("Vous devez saisir un nom pour le fichier !");
      return;
    }
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("files")
      .doc(renameFile)
      .update({
        name: filename,
        description: description,
      })
      .then(() => {
        fetchEvents();
        enqueueSnackbar("Fichier renommé !", { variant: "success" });
        setRenameFile(null);
      });
  };

  const onFileChange = (event) => {
    // Update the file to send to api.video
    setUploadFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleDownload = (path) => {
    firebase.storage
      .ref()
      .child(path)
      .getDownloadURL()
      .then((url) => {
        fetch(url).then((res) => {
          const linkElem = document.createElement("a");
          linkElem.href = res.url;
          // linkElem.download = "true";
          linkElem.target = "_blank";
          linkElem.click();
          linkElem.remove();
        });
      })
      .catch((e) => {
        if (e.code === "storage/object-not-found") {
          enqueueSnackbar(
            "Il semble que ce fichier ne soit plus disponible, merci de supprimer cet enregistrement",
            {
              variant: "warning",
            }
          );
        } else {
          enqueueSnackbar("Erreur lors de la récupération", {
            variant: "error",
          });
        }
      });
  };

  const handleDelete = () => {
    firebase.storage
      .ref()
      .child(deleteFile.path)
      .delete()
      .then(() => {
        firebase.db
          .collection("Ships")
          .doc(shipId)
          .collection("files")
          .doc(deleteFile.id)
          .delete()
          .then(() => {
            enqueueSnackbar("Document supprimé !", { variant: "success" });
            fetchEvents();
            setDeleteFile(null);
          })
          .catch(() => {
            enqueueSnackbar("Erreur lors de la suppression", {
              variant: "error",
            });
          });
      })
      .catch((e) => {
        if (e.code === "storage/object-not-found") {
          firebase.db
            .collection("Ships")
            .doc(shipId)
            .collection("files")
            .doc(deleteFile.id)
            .delete()
            .then(() => {
              enqueueSnackbar("Document supprimé !", { variant: "success" });
              fetchEvents();
              setDeleteFile(null);
            })
            .catch(() => {
              enqueueSnackbar("Erreur lors de la suppression", {
                variant: "error",
              });
            });
        } else {
          enqueueSnackbar("Erreur lors de la suppression", {
            variant: "error",
          });
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [events, setEvents] = useState([]);
  useEffect(() => {
    fetchEvents();
  }, []);

  const withFilters = (item) => {
    if (search == "") {
      return !item.validated;
    }
    if (item.name?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <div className={classes.SearchAndCardWrapper}>
        <TextField
          className={classes.searchbar}
          variant="outlined"
          placeholder="Recherche par nom"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  style={{ width: 25, height: 25 }}
                  src={require("../../Icons/search.png")}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />

        <svg
          style={{ fontSize: 45, cursor: "pointer" }}
          class="MuiSvgIcon-root jss174"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          onClick={() => setUpload(true)}
        >
          <path
            fill="#329646"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
          ></path>
        </svg>
      </div>
      <TableContainer>
        <Table aria-label="simple table" className={classes.head}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.col}>DATE</TableCell>
              <TableCell className={classes.col}>NOM</TableCell>
              <TableCell className={classes.col}>TELECHARGER</TableCell>
              <TableCell className={classes.col}>RENOMMER</TableCell>
              <TableCell className={classes.col}>SUPPRIMER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events
              .filter((item) => withFilters(item))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell>
                    {moment(
                      row.date?.toDate ? row.date.toDate() : row.date
                    ).format("DD/MM/YYYY")}
                  </TableCell>

                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.path === "easyLink" ? (
                      <Visibility
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (row.name.includes("://")) {
                            console.log("l310");
                            window.open(row.name);
                            return;
                          }
                          window.open(`https://${row.name}`);
                        }}
                      />
                    ) : (
                      <CloudDownload
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDownload(row.path);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Edit
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRenameFile(row.id);
                        setFilename(row.name);
                        setDescription(row.description);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Delete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDeleteFile(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={events.filter((item) => withFilters(item)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page"
      />

      <Dialog open={upload} onClose={() => setUpload(false)} fullWidth>
        <DialogTitle>Ajout de document</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              value={filename}
              onChange={(e) => setFilename(e.target.value)}
              variant="outlined"
              label="Nom du fichier"
            />
            <input type="file" hidden id="fileinput" onChange={onFileChange} />
            <label htmlFor="fileinput" style={{ margin: 10 }}>
              <Button color="primary" variant="contained" component="span">
                Choisir un fichier
              </Button>
            </label>
            {uploadFile && (
              <Typography>Selectionné : {uploadFile.name}</Typography>
            )}
            <p>OU</p>
            <TextField
              value={link}
              onChange={(e) => setLink(e.target.value)}
              variant="outlined"
              label="Ajouter un lien"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpload(false)}>Annuler</Button>
          <Button variant="contained" color="primary" onClick={handleUpload}>
            Enregistrer le fichier
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteFile} onClose={() => setDeleteFile(false)} fullWidth>
        <DialogTitle>Attention - {deleteFile?.name}</DialogTitle>
        <DialogContent>
          <Typography>
            Voulez vous vraiment supprimer ce document ? Cette action est
            irréversible
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteFile(false)}>Annuler</Button>

          <Button onClick={handleDelete}>Supprimer le document</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={renameFile} onClose={() => setRenameFile(null)} fullWidth>
        <DialogTitle>Renommer un document</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              value={filename}
              onChange={(e) => setFilename(e.target.value)}
              variant="outlined"
              label="Nom du fichier"
            />
            <div
              style={{
                marginTop: 10,
              }}
            >
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                label="Description"
                multiline
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRenameFile(null);
              setFilename("");
              setDescription("");
            }}
          >
            Annuler
          </Button>

          <Button onClick={handleRename}>Renommer le fichier</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  col: {
    color: "#00638F",
    borderBottomWidth: 0,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#DCEFF6",
    },
  },
  searchbar: {
    display: "flex",
    borderRadius: 50,
    width: "60%",
    margin: "10px auto",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    padding: "0 15px",
    textAlign: "center",
  },
  SearchAndCardWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkStyle: {
    color: "inherit",
  },
}));
export default Storage;
