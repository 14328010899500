import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Field } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  field: {
    borderRadius : 50,
    backgroundColor : "#000"
  },

}));

export default function InputText({
  name,
  label,
  type = "text",
  variant = "outlined",
  required = false,
  classes = {},
  value,
  ownOnChange = () => {},
  multiline = false,
}) {

  const classes1 = useStyles();

  return (
    <Field
      name={name}
      component="input"
      type={type}
      placeholder="First Name"
      label="email"
    >
      {props => (
        <TextField
          name={name}
          label={label}
          type={type}
          value={props.input.value}
          onChange={props.input.onChange}
          fullWidth
          required={required}
          variant={variant}
          margin="normal"
          multiline={multiline}
        />
      )}
    </Field>
  );
}
