import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI, { URL as _URL } from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import {
  Redirect,
  useHistory,
  Link,
  useRouteMatch,
  Switch,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InfosRecap from "./HomeComponents/InfosRecap";
import CahierTableau from "./HomeComponents/CahierTable";
import MaintenanceTableau from "./HomeComponents/MaintenanceTable";
import MyCalendar from "./HomeComponents/MyCalendar";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Datatable from "../Utils/Datatable";
import { Visibility, Delete } from "@material-ui/icons";
import axios from "axios";
import InputSelect from "../Inputs/InputSelect";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  navbar: {
    padding: theme.spacing(0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  button: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",
    fontWeight: "normal",
    color: "#00638F",
    minWidth: 180,
    flex: 1,
  },
  inputSelect: {
    width: "100%",
    height: 56,
    borderRadius: 50,
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 15,
    paddingLeft: 10,
    borderColor: "rgba(0, 0, 0, 0.2)",
    marginTop: 10,
  },
  selectedButton: {
    flex: 1,
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",
    fontWeight: "normal",
    color: "#FFF",
    backgroundColor: "#00638F",
    "&:hover": {
      backgroundColor: "#00638F",
    },
    minWidth: 180,
  },
  grid: {
    width: "100%",
  },
}));

export default function Home({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const [lines, setLines] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [exportLoad, setExportLoad] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [shipType, setShipType] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const { collection } = useParams();
  const shipSize = [
    "tous",
    "Jusqu'à 12m",
    "Entre 12m et 24,95m",
    "25m et plus",
  ];

  const addAbonnement = (user) => {
    firebase.db
      .collection(collection)
      .doc(user.id)
      .update({
        formule: "Pro +",
      })
      .then(() => {
        enqueueSnackbar("Abonnement activé !", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de l'activation de l'abonnement", {
          variant: "error",
        });
      });
  };

  const handleDelete = (item) => {
    firebase.db
      .collection(collection)
      .doc(item.id)
      .delete()
      .then(() => {
        enqueueSnackbar("Document supprimé !", { variant: "success" });
        setDeleteEvent(false);
        setSelected(null);
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la suppression", { variant: "error" });
      });
  };

  useEffect(() => {
    firebase.db
      .collection("ActivityTypes")
      .get()
      .then((snapshot) => {
        setShipType([...snapshot.docs.map((el) => el.data().value), "tous"]);
      })
      .catch((err) => console.error(err));
  }, []);

  const AddButton = () => {
    return (
      <Button
        style={{ marginLeft: "auto" }}
        variant="contained"
        color="primary"
        onClick={() => {
          setAdd(true);
        }}
      >
        Ajouter
      </Button>
    );
  };

  const handleAdd = (values) => {
    if (collection === "Titles_Reglementaire") {
      if (!values.label) {
        values.label = null;
      }
      if (!values.categorie) {
        values.categories = null;
      }
      values.type = selectedType;
      values.taille = selectedSize;
    }
    firebase.db
      .collection(collection)
      .add({ ...values, createdAt: new Date() })
      .then(() => {
        enqueueSnackbar("Document ajouté", { variant: "success" });
      });
  };

  const handleExport = async () => {
    if (exportLoad) {
      enqueueSnackbar("Un export est déjà en cours", { variant: "warning" });
      return;
    }
    enqueueSnackbar("Création de l'export en cours", { variant: "success" });
    setExportLoad(true);
    const res = await axios.get(_URL + "rapport/" + selected.id, {
      responseType: "blob",
    });
    console.log(res);
    const blob = await res.data;
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "download";
    a.hidden = true;
    a.click();
    setExportLoad(false);
  };

  const handleUpdate = (values) => {
    firebase.db
      .collection(collection)
      .doc(update.id)
      .update(values)
      .then(() => {
        enqueueSnackbar("Document modifié", { variant: "success" });
      });
  };

  const getData = () => {
    setLines([]);
    firebase.db.collection(collection).onSnapshot((values) => {
      let tmp = [];
      values.forEach((doc) => {
        tmp.push({
          id: doc.id,
          ...doc.data(),
          /* see: () => (
            <Visibility
              color="#000"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelected({ id: doc.id, ...doc.data() });
              }}
            />
          ), */
        });
      });
      console.log(tmp);
      setLines(tmp);
    });
  };
  useEffect(() => {
    getData();
  }, [collection]);

  useEffect(() => {
    let tmp = [];
    let keys = ["see"];
    setColumns([]);
    lines.forEach((line) => {
      Object.keys(line).forEach((key) => {
        if (!keys.includes(key)) {
          if (key === "createdAt") {
            tmp.push({
              field: "createdAt",
              headerName: "Date de création",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                if (params.row.createdAt) {
                  return moment(params.row.createdAt?.toDate()).format(
                    "DD/MM/YYYY"
                  );
                }
                return null;
              },
            });
          } else if (key === "firstDevisAt") {
            tmp.push({
              field: "firstDevisAt",
              headerName: "Premier devis",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                if (params.row.createdAt) {
                  return moment(params.row.createdAt?.toDate()).format(
                    "DD/MM/YYYY"
                  );
                }
                return null;
              },
            });
          } else if (key === "newDevisAt") {
            tmp.push({
              field: "newDevisAt",
              headerName: "Nouveau devis",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                if (params.row.newDevisAt) {
                  return moment(params.row.createdAt?.toDate()).format(
                    "DD/MM/YYYY"
                  );
                }
                return null;
              },
            });
          } else if (key === "modifiedMeeting") {
            tmp.push({
              field: "modifiedMeeting",
              headerName: "Modif. meeting",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                if (params.row.newDevisAt) {
                  return moment(params.row.createdAt?.toDate()).format(
                    "DD/MM/YYYY"
                  );
                }
                return null;
              },
            });
          } else if (key === "modifiedMeeting") {
            tmp.push({
              field: "modifiedMeeting",
              headerName: "Modif. meeting",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                if (params.row.newDevisAt) {
                  return moment(params.row.createdAt?.toDate()).format(
                    "DD/MM/YYYY"
                  );
                }
                return null;
              },
            });
          } else if (key === "positions") {
            tmp.push({
              field: "positions",
              headerName: "Position",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return `${params.row.lat}, ${params.row.lng}`;
              },
            });
          } else if (key === "fournisseur") {
            tmp.push({
              field: "fournisseur",
              headerName: "Fournisseur",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return params.id;
              },
            });
          } else if (key === "lines") {
            tmp.push({
              field: "lines",
              headerName: "Lines",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return params.row.lines.length;
              },
            });
          } else if (key === "user") {
            tmp.push({
              field: "utilisateur",
              headerName: "Utilisateur",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return params.id;
              },
            });
          } else if (key === "prospect") {
            tmp.push({
              field: "prospect",
              headerName: "Prospect",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return params.row.id;
              },
            });
          } else if (key === "userRef") {
            tmp.push({
              field: "UserRef",
              headerName: "UserRef",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return params.row.id;
              },
            });
          } else if (key === "modifiedBy") {
            tmp.push({
              field: "modifiedBy",
              headerName: "Modifié par",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return params.row.id;
              },
            });
          } else if (key === "devisRef") {
            tmp.push({
              field: "devisRef",
              headerName: "devisRef",
              disableClickEventBubbling: true,
              width: 200,
              valueGetter: (params) => {
                return params.row.id;
              },
            });
          } else {
            tmp.push({
              field: key,
              headerName: key,
              width: 200,
              disableClickEventBubbling: true,
            });
          }

          keys.push(key);
        }
      });
    });
    console.log(tmp);
    tmp.unshift(
      ...[
        // {
        //   field: "see",
        //   headerName: "Details",
        //   disableClickEventBubbling: true,
        //   renderCell: (params) => {
        //     return (
        //       <Visibility
        //         color="#000"
        //         style={{ cursor: "pointer" }}
        //         onClick={() => {
        //           setSelected(params.row);
        //         }}
        //       />
        //     );
        //   },
        // },
        {
          field: "delete",
          headerName: "Supprimer",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            return (
              <Delete
                color="#000"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDelete(params.row);
                }}
              />
            );
          },
        },
      ]
    );
    if (collection === "users") {
      tmp.unshift(
        ...[
          {
            field: "addSub",
            headerName: "Activer",
            disableClickEventBubbling: true,
            width: 200,
            renderCell: (params) => {
              return (
                <Button
                  onClick={() => {
                    addAbonnement(params.row);
                  }}
                  color="primary"
                >
                  Activer l'abonnement
                </Button>
              );
            },
          },
        ]
      );
    }
    setColumns(tmp);
  }, [lines]);
  /* useEffect(() => {
    shipsRef.doc(shipId).onSnapshot((doc) => {
      if (doc.data() === null) {
        history.replace("/dashboard");
      } else {
        setShip(doc.data());
      }
    });
  }, [shipId]); */

  const handleUsurpation = () => {
    window
      .open(
        `https://maintandsea.fly.dev/usurpate?uid=${
          selected.armateur || selected.uid
        }`,
        "_blank"
      )
      .focus();
  };
  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid className={classes.grid}>
          <Typography
            component="h1"
            variant="h1"
            style={{
              fontSize: 50,
              color: "#388ADC",
              textDecoration: "none",
              margin: "50px 0",
              marginLeft: "15%",
            }}
          >
            Prospection commerciale
          </Typography>
          <Container>
            <Datatable
              title={collection}
              columns={columns}
              rows={lines}
              // AddButton={AddButton}
            />
          </Container>
        </Grid>
      </Grid>
      <Dialog open={add} onClose={() => setAdd(false)} fullWidth>
        <DialogTitle>Ajouter un document à la collection</DialogTitle>
        <FinalForm onSubmit={handleAdd}>
          <DialogContent>
            {columns.map((property) => {
              return !["see", "id", "createdAt"].includes(property.field) ? (
                property.field === "type" ? (
                  <select
                    className={classes.inputSelect}
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      type
                    </option>
                    <option
                      className={classes.placeHolderSelect}
                      value={"tous"}
                    >
                      tous
                    </option>
                    {shipType.map((el, i) => {
                      return (
                        <option
                          className={classes.placeHolderSelect}
                          value={el}
                        >
                          {el}
                        </option>
                      );
                    })}
                  </select>
                ) : property.field === "size" ? (
                  <select
                    className={classes.inputSelect}
                    onChange={(e) => {
                      setSelectedSize(e.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      taille
                    </option>
                    {shipSize.map((el, i) => {
                      return <option value={el}>{el}</option>;
                    })}
                  </select>
                ) : (
                  <InputText
                    name={property.field}
                    label={property.field}
                    placeholder={property.field}
                  />
                )
              ) : null;
            })}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAdd(false);
              }}
            >
              Annuler
            </Button>
            <Button type="submit">Ajouter</Button>
          </DialogActions>
        </FinalForm>
      </Dialog>
      <Dialog
        open={!!update}
        defaultValue={update}
        onClose={() => setUpdate(false)}
        fullWidth
      >
        <DialogTitle>Modifier un document de la collection</DialogTitle>
        <FinalForm onSubmit={handleUpdate} initialValues={update}>
          <DialogContent>
            {columns.map((property) => {
              return !["see", "id", "createdAt"].includes(property.field) ? (
                property.field === "type" ? (
                  <>
                    <Typography className={classes.label}>
                      Type d'activité
                    </Typography>
                    <InputSelect
                      content={shipType.map((el) => ({
                        label: el,
                        id: el,
                      }))}
                      name="type"
                    />
                  </>
                ) : property.field === "size" ? (
                  <>
                    <Typography className={classes.label}>Taille</Typography>
                    <InputSelect
                      content={shipSize.map((el) => ({
                        label: el,
                        id: el,
                      }))}
                      name="size"
                    />
                  </>
                ) : (
                  <InputText
                    name={property.field}
                    label={property.field}
                    placeholder={property.field}
                  />
                )
              ) : null;
            })}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setUpdate(false);
              }}
            >
              Annuler
            </Button>
            <Button type="submit">Modifier</Button>
          </DialogActions>
        </FinalForm>
      </Dialog>

      <Dialog open={!!selected} onClose={() => setSelected(null)} fullWidth>
        <DialogTitle>Détails - id : {selected?.id} </DialogTitle>

        <DialogContent>
          {columns.map((property) => {
            return property.field !== "see" && !!selected ? (
              property.field === "createdAt" ? (
                <div>
                  <h3>{property.field}</h3>
                  <p>
                    {moment(selected[property.field]?.toDate()).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                </div>
              ) : (
                <div>
                  <h3>{property.field}</h3>
                  <p>{selected[property.field]}</p>
                </div>
              )
            ) : null;
          })}
        </DialogContent>
        <DialogActions>
          {collection === "Ships" && (
            <>
              <Button onClick={handleExport}>Export</Button>
            </>
          )}
          {["Ships", "Armateurs"].includes(collection) && (
            <Button onClick={handleUsurpation}>Voir plus</Button>
          )}

          {collection === "Partenariats" && (
            <>
              <Button onClick={handleExport}>Export</Button>
            </>
          )}

          <Button
            onClick={() => {
              setSelected(null);
            }}
          >
            Fermer
          </Button>
          <Button
            onClick={() => {
              console.log(selected);
              setUpdate(selected);
            }}
          >
            Modifier
          </Button>

          <Button
            onClick={() => {
              setDeleteEvent(true);
            }}
          >
            Supprimer
          </Button>
        </DialogActions>
        <Dialog
          open={deleteEvent}
          onClose={() => setDeleteEvent(false)}
          fullWidth
        >
          <DialogTitle>Attention</DialogTitle>
          <DialogContent>
            <Typography>
              Voulez vous vraiment supprimer ce document ? Cette action est
              irréversible
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteEvent(false)}>Annuler</Button>

            <Button onClick={handleDelete}>Supprimer l'évènement</Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </>
  );
}
