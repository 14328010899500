import React from "react";
import {
  Page,
  Text,
  PDFViewer,
  View,
  Image,
  Document,
  StyleSheet,
  Rect,
  Svg,
  Link,
} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    border: "1px solid #000",
    paddingVertical: 40,
    color: "#3a3a3a",
    size: 12,
  },
  section: {
    flex: 1,
  },
  docTitle: {
    textAlign: "center",
    fontSize: 13,
    textTransform: "uppercase",
  },
  banner: {
    flexDirection: "row",
    alignItems: "center",
    height: 80,

    marginVertical: 10,
  },
  content: {
    paddingHorizontal: 40,
    flexGrow: 1,
  },
  title: {
    width: "100%",
    fontSize: 25,
    fontWeight: "bold",
    borderBottom: "4px solid #3a3a3a",
    marginVertical: 20,
  },
  multitext: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  footer: {
    marginHorizontal: 40,
    flex: 1,
    maxHeight: 30,
    borderTop: "4px solid #3a3a3a",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  footerBlock: {
    height: 30,
    width: 30,
    backgroundColor: "#3a3a3a",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    marginLeft: 10,
  },
  contentLine: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontStyle: "italic",
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 40,
    marginBottom: 20,
  },
  thead: {
    width: "100%",
    backgroundColor: "#b0b3b2",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "center",
    fontSize: 8,
  },
  th: {
    flexDirection: "column",
    fontSize: 8,

    border: "1px solid #3a3a3a",
    paddingHorizontal: 2,
    paddingVertical: 2,
  },
});
const Rapport = ({
  ship,
  cahier,
  cahierGraphic,
  hydro,
  hydroGraphics,
  maintenance,
  maintenanceGraphic,
  startDate,
  endDate,
  cahierCharts,
  maintenanceCharts,
  hydroCharts,
  oilCharts,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.docTitle}>
          RAPPORT D’ACTIVITES DE MAINTENANCE _ {ship.name} _ MAINT & SEA
        </Text>
        <View style={styles.banner}>
          <Svg width={50} height={100} viewBox="0 0 50 80">
            <Rect width={50} height={80} fill="#3a3a3a" />
          </Svg>
          <Image
            src="https://firebasestorage.googleapis.com/v0/b/maint-and-sea.appspot.com/o/icon.png?alt=media&token=68461b12-28d0-4b09-9f88-d8c93fa67bd3"
            style={{
              width: 70,
              height: 70,
              margin: 10,
              borderRadius: 10,
            }}
          />
          <View
            style={{
              flex: 1,

              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingRight: 40,
            }}
          >
            <Text
              style={{
                fontSize: 47,
                fontWeight: "bold",
              }}
            >
              {ship.name}
            </Text>
            <Text
              style={{
                fontSize: 27,
                fontStyle: "italic",
              }}
            >
              {ship.immat}
            </Text>
          </View>
        </View>
        <Text
          style={{
            margin: 20,
            marginBottom: 40,
          }}
        >
          {moment().locale("fr").format("MMMM YYYY")} _ {ship.port}
        </Text>
        <View style={styles.content}>
          <Text style={styles.title}>RAPPORT D'ACTIVITÉS</Text>

          <Text>
            Ce rapport centralise les informations de maintenance, saisies à
            travers l’outil de digitalisation,
            <Text style={{ fontWeight: "bold" }}> MAINT & SEA</Text>
            <Link src="https://maintandsea.fr"> https://maintandsea.fr</Link>
          </Text>

          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              padding: 60,
            }}
          >
            {ship.image && <Image source={ship.image} />}
          </View>
        </View>
        <View style={styles.footer}>
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={styles.title}>TABLE DES MATIÈRES</Text>
          <View style={styles.contentLine}>
            <Text>RAPPORT D'ACTIVITÉS</Text>
          </View>
          <View style={styles.contentLine}>
            <Text>CAHIER MACHINE</Text>
          </View>
          <View style={styles.contentLine}>
            <Text>HYDROCARBURES</Text>
            <Text></Text>
          </View>
          <View style={styles.contentLine}>
            <Text>MAINTENANCE REGLEMENTAIRE</Text>
            <Text></Text>
          </View>
          <View style={styles.contentLine}>
            <Text>COORDONNÉES</Text>
          </View>

          <Text
            style={{
              marginTop: 50,
              marginBottom: 15,
              fontWeight: "bold",
            }}
          >
            Résumé
          </Text>
          <Text>
            Le document analyse les évènements de maintenance du{" "}
            {moment(startDate).locale("fr").format("LL")} au{" "}
            {moment(endDate).locale("fr").format("LL")} à travers trois
            catégories :
          </Text>
          <Text style={{ margin: 15, textAlign: "center" }}>
            Cahier Machine, Hydrocarbures et Maintenance Réglementaire.
          </Text>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center" }}>
              Le détail des données est consultable via l’application mobile
              Maint & Sea et sur l’accès webapp{" "}
              <Link src="https://app.maintandsea.fr/login">
                https://app.maintandsea.fr/login
              </Link>
            </Text>
            <Image
              src="https://firebasestorage.googleapis.com/v0/b/maint-and-sea.appspot.com/o/icon.png?alt=media&token=68461b12-28d0-4b09-9f88-d8c93fa67bd3"
              style={{
                width: 100,
                height: 100,
                borderRadius: 10,
              }}
            />
          </View>
        </View>
        <View style={styles.footer}>
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={styles.title}>CAHIER MACHINE</Text>
          <View style={styles.section}>
            <Text style={{ ...styles.subtitle, fontSize: 14 }}>
              GRAPHIQUE: Nombre de maintenance saisie par équipement
            </Text>

            <Image
              src={cahierCharts}
              style={{
                width: "100%",
                height: "100%",
                margin: 10,
                borderRadius: 10,
              }}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              TABLEAU DÉTAILLÉ CAHIER MACHINE :
            </Text>
            <Text>
              Données triées par des évènements saisis du plus récent au plus
              ancien.
            </Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.thead}>
            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Date</Text>
            </View>
            <View style={{ ...styles.th, width: 200, maxWidth: 200 }}>
              <Text>Description détaillée</Text>
            </View>
            <View style={{ ...styles.th, width: 200, maxWidth: 200 }}>
              <Text>Description</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Équipement</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Horodatage</Text>
            </View>
            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Récurrence</Text>
            </View>
            <View style={{ ...styles.th, width: 50, maxWidth: 50 }}>
              <Text>Unité</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Titre</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Type</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Moteur</Text>
            </View>
          </View>

          {cahier.map((el, i) => (
            <View
              key={i}
              style={{
                ...styles.thead,
                backgroundColor: "#FFF",
              }}
            >
              <View
                wrap={false}
                style={{ ...styles.th, width: 70, maxWidth: 70 }}
              >
                <Text>{moment(el.date).format("DD/MM/YY")}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 200, maxWidth: 200 }}
              >
                <Text>{el.descriptionDetail}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 200, maxWidth: 200 }}
              >
                {!!el.descriptions &&
                  el.descriptions.map((_el) => (
                    <Text key={_el.value}>{_el.value}</Text>
                  ))}
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.equipement}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.horodatage}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 70, maxWidth: 70 }}
              >
                <Text>{el.reccurrence}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 50, maxWidth: 50 }}
              >
                <Text>{el.reccurence_unit}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.title}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.type}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.moteur?.toString()}</Text>
              </View>
            </View>
          ))}
        </View>
        <View
          fixed
          style={{
            ...styles.footer,
            height: 100,
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0,
          }}
        >
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={styles.title}>HYDROCARBURES</Text>
          <View style={styles.section}>
            <Text style={{ ...styles.subtitle, fontSize: 14 }}>
              GRAPHIQUE: Quantité de carburant
            </Text>
            <Image
              src={hydroCharts}
              style={{
                width: "100%",
                height: "100%",
                margin: 10,
                borderRadius: 10,
              }}
            />
          </View>
          <View style={styles.section}>
            <Text style={{ ...styles.subtitle, fontSize: 14 }}>
              GRAPHIQUE: Quantité d'huile
            </Text>
            <Image
              src={oilCharts}
              style={{
                width: "100%",
                height: "100%",
                margin: 10,
                borderRadius: 10,
              }}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>TABLEAU DÉTAILLÉ :</Text>
            <Text>
              Données triées par des évènements saisis du plus récent au plus
              ancien.
            </Text>
            <Text>Catégories répertoriées :</Text>
            <Text>- Quantité embarquée ou débarquée</Text>
            <Text>- Port de chargement</Text>
            <Text>- Type d’hydrocarbure</Text>
            <Text>- Équipement concerné</Text>
            <Text>- Coût</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.thead}>
            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Date</Text>
            </View>
            <View style={{ ...styles.th, width: 200, maxWidth: 200 }}>
              <Text>Description détaillée</Text>
            </View>
            <View style={{ ...styles.th, width: 200, maxWidth: 200 }}>
              <Text>Description</Text>
            </View>

            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Horodatage</Text>
            </View>
            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Quantité</Text>
            </View>
            <View style={{ ...styles.th, width: 50, maxWidth: 50 }}>
              <Text>Unité</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Titre</Text>
            </View>
            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Prix</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Hydrocarbure</Text>
            </View>
            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Moteur</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Port</Text>
            </View>
          </View>

          {hydro.map((el, i) => (
            <View
              key={i}
              style={{
                ...styles.thead,
                backgroundColor: "#FFF",
              }}
            >
              <View
                wrap={false}
                style={{ ...styles.th, width: 70, maxWidth: 70 }}
              >
                <Text>{moment(el.date).format("DD/MM/YY")}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 200, maxWidth: 200 }}
              >
                <Text>{el.descriptionDetail}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 200, maxWidth: 200 }}
              >
                {el.descriptions &&
                  el.descriptions.map((_el, i) => <Text>{_el.value}</Text>)}
              </View>

              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.horodatage}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 70, maxWidth: 70 }}
              >
                <Text>{el.quantity}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 50, maxWidth: 50 }}
              >
                <Text>{el.quantityUnit}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.title}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 70, maxWidth: 70 }}
              >
                <Text>{el.prix}</Text>
              </View>
              <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
                <Text>{el.hydrocarbure}</Text>
              </View>
              <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
                <Text>{el.moteur?.toString()}</Text>
              </View>
              <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
                <Text>{el.port}</Text>
              </View>
            </View>
          ))}
        </View>
        <View
          fixed
          style={{
            ...styles.footer,
            height: 100,
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0,
          }}
        >
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={styles.title}>MAINTENANCE RÉGLEMENTAIRE</Text>
          <View style={styles.section}>
            <Text style={{ ...styles.subtitle, fontSize: 14 }}>
              GRAPHIQUE: Nombre de maintenance rélgmentaire saisie
            </Text>
            <Image
              src={maintenanceCharts}
              style={{
                width: "100%",
                height: "100%",
                margin: 10,
                borderRadius: 10,
              }}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>TABLEAU DÉTAILLÉ :</Text>
            <Text>
              Données triées par des évènements saisis du plus récent au plus
              ancien.
            </Text>
            <Text>Catégories répertoriées :</Text>
            <Text>- Structure</Text>
            <Text>- Asséchement</Text>
            <Text>- Incendie</Text>
            <Text>- Sécurité</Text>
            <Text>- Communication</Text>
            <Text>- Certificats</Text>
            <Text>- Repérage</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.thead}>
            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Date</Text>
            </View>
            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Titre</Text>
            </View>
            <View style={{ ...styles.th, width: 200, maxWidth: 200 }}>
              <Text>Description</Text>
            </View>
            <View style={{ ...styles.th, width: 200, maxWidth: 200 }}>
              <Text>Description détaillée</Text>
            </View>

            <View style={{ ...styles.th, width: 70, maxWidth: 70 }}>
              <Text>Récurrence</Text>
            </View>
            <View style={{ ...styles.th, width: 50, maxWidth: 50 }}>
              <Text>Unité</Text>
            </View>

            <View style={{ ...styles.th, width: 100, maxWidth: 100 }}>
              <Text>Type</Text>
            </View>
          </View>

          {maintenance.map((el, i) => (
            <View
              key={i}
              style={{
                ...styles.thead,
                backgroundColor: "#FFF",
              }}
            >
              <View
                wrap={false}
                style={{ ...styles.th, width: 70, maxWidth: 70 }}
              >
                <Text>{moment(el.date).format("DD/MM/YY")}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.title}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 200, maxWidth: 200 }}
              >
                {el.descriptions &&
                  el.descriptions.map((_el) => <Text>{_el.value}</Text>)}
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 200, maxWidth: 200 }}
              >
                <Text>{el.descriptionDetail}</Text>
              </View>

              <View
                wrap={false}
                style={{ ...styles.th, width: 70, maxWidth: 70 }}
              >
                <Text>{el.reccurrence}</Text>
              </View>
              <View
                wrap={false}
                style={{ ...styles.th, width: 50, maxWidth: 50 }}
              >
                <Text>{el.reccurrence_unit}</Text>
              </View>

              <View
                wrap={false}
                style={{ ...styles.th, width: 100, maxWidth: 100 }}
              >
                <Text>{el.type}</Text>
              </View>
            </View>
          ))}
        </View>
        <View
          fixed
          style={{
            ...styles.footer,
            height: 100,
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0,
          }}
        >
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={styles.title}>COORDONNÉES</Text>
          <Text
            style={{
              marginTop: 50,
              marginBottom: 15,
              fontWeight: "bold",
              alignItems: "center",
            }}
          >
            {"Carine Baranger\nPrésidente"}
          </Text>
          <Text style={{ textAlign: "center" }}>MAINT & SEA</Text>
          <Link src="https://maintandsea.fr/" style={{ textAlign: "center" }}>
            https://maintandsea.fr/
          </Link>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src="https://firebasestorage.googleapis.com/v0/b/maint-and-sea.appspot.com/o/icon.png?alt=media&token=68461b12-28d0-4b09-9f88-d8c93fa67bd3"
              style={{
                width: 100,
                height: 100,
                marginBottom: 40,
                borderRadius: 10,
              }}
            />
            <Text style={{ textAlign: "center", marginBottom: 15 }}>
              0298822513 | 0787374228
            </Text>
            <Text style={{ textAlign: "center", marginBottom: 15 }}>
              c.baranger@andsea.fr
            </Text>
            <Text style={{ textAlign: "center", marginBottom: 15 }}>
              Quai d'Estienne d'Orves, 29130, LE GUILVINEC
            </Text>
          </View>
          <Text style={{ textAlign: "center", marginBottom: 15 }}>
            SAS PERDRIX _ Capital : 15.000€ _ N° SIREN : 897 451 720
          </Text>
        </View>
        <View style={{ ...styles.footer, height: 100 }}>
          <Text>{moment().locale("fr").format("DD MMMM YYYY")}</Text>
          <View style={styles.footerBlock}>
            <Text render={({ pageNumber }) => pageNumber}></Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Rapport;
