import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import html2canvas from "html2canvas";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import {
  Redirect,
  useHistory,
  Link,
  useRouteMatch,
  Switch,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
} from "recharts";
import InfosRecap from "./HomeComponents/InfosRecap";
import CahierTableau from "./HomeComponents/CahierTable";
import MaintenanceTableau from "./HomeComponents/MaintenanceTable";
import HydrocarbureTableau from "./HomeComponents/HydroTable";
import MyCalendar from "./HomeComponents/MyCalendar";
import {
  AccessTime,
  InvertColors,
  Storage as StorageIcon,
} from "@material-ui/icons";
import Storage from "./HomeComponents/Storage";
import Horodatage from "./HomeComponents/Horodatage";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Checklist from "./HomeComponents/Checklist";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InputText from "../Inputs/InputText";
import FinalForm from "../Utils/FinalForm";
import Rapport from "../Components/Rapport";
import { PDFDownloadLink } from "@react-pdf/renderer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: "15%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  navbar: {
    padding: theme.spacing(0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  button: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",
    fontWeight: "normal",
    color: "#00638F",
    minWidth: 230,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  selectedButton: {
    flex: 1,
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",

    fontWeight: "normal",
    color: "#FFF",
    backgroundColor: "#00638F",
    "&:hover": {
      backgroundColor: "#00638F",
    },
    minWidth: 230,
  },
  grid: {
    width: "100%",
  },
}));

export default function Ship({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const shipsRef = firebase.db.collection("Ships");
  const [ships, setShips] = useState([]);
  const [ship, setShip] = useState({});
  const { shipId } = useParams();
  let match = useRouteMatch("/ship/:shipId");
  const location = useLocation();
  const [openReport, setOpenReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cahierCharts, setCahierCharts] = useState([]);
  const [maintenanceCharts, setMaintenanceCharts] = useState([]);
  const [yearsBetweenSelected, setYearsBetweenSelected] = useState([]);
  const [hydroCharts, setHydroCharts] = useState([]);
  const [pdf, setPdf] = useState(null);

  const printRef = React.useRef();
  const printRefMaintenance = React.useRef();
  const printRefHydro = React.useRef();
  console.log(location.pathname);
  console.log(match.url + "/cahier");
  console.log(location.pathname === match.url + "/cahier");
  const getShips = () => {
    shipsRef
      .where("armateur", "==", cookies.userToken)
      .get()
      .then((docs) => {
        let tmp = [];
        docs.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        console.log(tmp);
        setShips(tmp);
      });
  };
  useEffect(() => {
    getShips();
  }, []);

  useEffect(() => {
    shipsRef.doc(shipId).onSnapshot((doc) => {
      if (doc.data() === null) {
        history.replace("/dashboard");
      } else {
        setShip(doc.data());
      }
    });
  }, [shipId]);
  const handleSubmit = async (values) => {
    console.log(values);
    if (values.startDate > values.endDate) {
      enqueueSnackbar(
        "La date de début doit être inférieure à la date de fin",
        {
          variant: "error",
        }
      );
      return;
    }
    setLoading(true);
    const startDate = values.startDate.replaceAll("-", "/");
    const endDate = values.endDate.replaceAll("-", "/");
    console.log(startDate, endDate);

    function generateYearsBetween(
      startYear = parseInt(startDate.split("/")[0]),
      endYear = parseInt(endDate.split("/")[0])
    ) {
      const endDate = endYear || new Date().getFullYear();
      let years = [];

      for (var i = startYear; i <= endDate; i++) {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        console.log("l214 ", randomColor);
        years.push({
          year: startYear,
          color: randomColor,
        });
        startYear++;
      }
      return years;
    }

    const yearsBetween = generateYearsBetween();
    setYearsBetweenSelected(generateYearsBetween());

    const cahierDocs = await shipsRef
      .doc(shipId)
      .collection("event_cahier")
      .where("date", ">=", startDate)
      .where("date", "<=", endDate)
      .orderBy("date", "desc")
      .get();
    const maintenanceDocs = await shipsRef
      .doc(shipId)
      .collection("event_maintenance")
      .where("date", ">=", startDate)
      .where("date", "<=", endDate)
      .orderBy("date", "desc")
      .get();
    const hydroDocs = await shipsRef
      .doc(shipId)
      .collection("event_hydro")
      .where("date", ">=", startDate)
      .where("date", "<=", endDate)
      .orderBy("date", "desc")
      .get();

    // cahier chart
    let compteur = [];
    cahierDocs.forEach((el, i) => {
      const data = el.data();
      let smallName = data.equipement;
      switch (data.equipement) {
        case "EQUIPEMENT GUINDEAU":
          smallName = "GUINDEAU";
          break;
        case "GROUPE CHAUD":
          smallName = "GRP CHAUD";
          break;
        case "AUXILIAIRES MACHINES (pompes, hydrophore, hydraulique)":
          smallName = "AUX MACH.";
          break;
      }
      if (data.equipement) {
        const i = compteur.findIndex((e) => e.name === smallName);
        if (i > -1) {
          compteur[i].Nombre = compteur[i].Nombre + 1;
        } else {
          compteur.push({
            name: smallName,
            Nombre: 1,
          });
        }
      }
    });

    // maintenace chart
    let compteurMaintenance = [];
    maintenanceDocs.forEach((el, i) => {
      const data = el.data();
      let smallName = data.type;
      if (data.type) {
        const i = compteurMaintenance.findIndex((e) => e.name === smallName);
        const year = data.date.split("/")[0];
        if (i > -1) {
          compteurMaintenance[i][`${year}`] =
            compteurMaintenance[i][`${year}`] + 1;
        } else {
          let maintenanceObject = {
            name: smallName,
          };
          // yearsBetween.map((el) => {
          //   maintenanceObject = {
          //     ...maintenanceObject,
          //     el: 0,
          //   };
          // });

          compteurMaintenance.push(maintenanceObject);
          const itemCompteur = compteurMaintenance.findIndex(
            (e) => e.name === smallName
          );
          compteurMaintenance[itemCompteur][`${year}`]
            ? (compteurMaintenance[itemCompteur][`${year}`] += 1)
            : (compteurMaintenance[itemCompteur][`${year}`] = 1);
        }
      }
    });

    // hydro chart
    let compteurHydro = [
      {
        trimestre: "Trimestre 1",
        2021: 0,
        2022: 0,
        2023: 0,
      },
      {
        trimestre: "Trimestre 2",
      },
      {
        trimestre: "Trimestre 3",
      },
      {
        trimestre: "Trimestre 4",
      },
    ];
    hydroDocs.forEach((el, i) => {
      const data = el.data();
      const currentYear = data.date.split("/")[0];
      const trimestreFilter = (year) => {
        if (
          Date.parse(data.date) >= Date.parse(`${year}/01/21`) &&
          Date.parse(data.date) <= Date.parse(`${year}/03/31`)
        ) {
          compteurHydro[0][currentYear]
            ? (compteurHydro[0][currentYear] =
                compteurHydro[0][currentYear] + parseInt(data.quantity))
            : (compteurHydro[0][currentYear] = 1);
          return;
        }
        if (
          Date.parse(data.date) >= Date.parse(`${year}/03/01`) &&
          Date.parse(data.date) <= Date.parse(`${year}/06/30`)
        ) {
          compteurHydro[1][currentYear]
            ? (compteurHydro[1][currentYear] =
                compteurHydro[1][currentYear] + parseInt(data.quantity))
            : (compteurHydro[1][currentYear] = 1);
          return;
        }
        if (
          Date.parse(data.date) >= Date.parse(`${year}/07/01`) &&
          Date.parse(data.date) <= Date.parse(`${year}/09/30`)
        ) {
          compteurHydro[2][currentYear]
            ? (compteurHydro[2][currentYear] =
                compteurHydro[2][currentYear] + parseInt(data.quantity))
            : (compteurHydro[2][currentYear] = 1);
          return;
        }
        if (
          Date.parse(data.date) >= Date.parse(`${year}/10/01`) &&
          Date.parse(data.date) <= Date.parse(`${year}/12/31`)
        ) {
          compteurHydro[3][currentYear]
            ? (compteurHydro[3][currentYear] =
                compteurHydro[3][currentYear] + parseInt(data.quantity))
            : (compteurHydro[3][currentYear] = 1);
          return;
        }
      };
      trimestreFilter(currentYear);
    });

    setHydroCharts(compteurHydro);
    setCahierCharts(compteur);
    setMaintenanceCharts(compteurMaintenance);

    const chartElement = printRef.current;
    const canvas = await html2canvas(chartElement);
    const chartImg = canvas.toDataURL("image/png");
    const chartElementMaintenance = printRefMaintenance.current;
    const canvasMaintenance = await html2canvas(chartElementMaintenance);
    const chartImgMaintenance = canvasMaintenance.toDataURL("image/png");
    const chartElementHydro = printRefHydro.current;
    const canvasHydro = await html2canvas(chartElementHydro);
    const chartImgHydro = canvasHydro.toDataURL("image/png");

    setPdf({
      cahier: cahierDocs.docs.map((e) => e.data()),
      maintenance: maintenanceDocs.docs.map((e) => e.data()),
      hydro: hydroDocs.docs.map((e) => e.data()),
      ship: ship,
      startDate: startDate,
      endDate: endDate,
      cahierCharts: chartImg || null,
      maintenanceCharts: chartImgMaintenance || null,
      hydroCharts: chartImgHydro || null,
    });
    setLoading(false);
  };
  return (
    <Grid container component="main" className={classes.root}>
      <div
        ref={printRef}
        style={{
          position: "absolute",
          top: -2000,
          left: 0,
          width: 1460,
          height: 710,
          backgroundColor: "white",
        }}
      >
        <BarChart
          width={1450}
          height={700}
          data={cahierCharts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            style={{
              fontSize: 12,
            }}
          />
          <YAxis
            style={{
              fontSize: "2rem",
            }}
          />
          <Tooltip />
          <Legend iconSize={36} />
          <Bar dataKey="Nombre" fill="#8884d8" isAnimationActive={false} />
        </BarChart>
      </div>
      <div
        ref={printRefMaintenance}
        style={{
          position: "absolute",
          top: -2000,
          left: 0,
          width: 1460,
          height: 710,
          backgroundColor: "white",
          zIndex: 1000,
        }}
      >
        <BarChart
          width={1450}
          height={700}
          data={maintenanceCharts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            style={{
              fontSize: 12,
            }}
          />
          <YAxis
            style={{
              fontSize: "2rem",
            }}
          />
          <Tooltip />
          <Legend iconSize={36} />
          {yearsBetweenSelected.map((el) => {
            return (
              <Bar
                dataKey={el.year}
                fill={`#${el.color}`}
                isAnimationActive={false}
              />
            );
          })}
        </BarChart>
      </div>
      <div
        ref={printRefHydro}
        style={{
          position: "absolute",
          top: -2000,
          left: 0,
          width: 1460,
          height: 710,
          backgroundColor: "white",
          zIndex: 1000,
        }}
      >
        <BarChart
          width={1450}
          height={700}
          data={hydroCharts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="trimestre"
            style={{
              fontSize: 12,
            }}
          />
          <YAxis
            style={{
              fontSize: "2rem",
            }}
          />
          <Tooltip />
          <Legend iconSize={36} />
          {yearsBetweenSelected.map((el) => (
            <Bar
              dataKey={el.year}
              fill={`#${el.color}`}
              isAnimationActive={false}
            />
          ))}
        </BarChart>
      </div>
      <CssBaseline />
      <Grid className={classes.grid}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            margin: "50px 0",
            marginLeft: "15%",
          }}
        >
          MAINT & SEA
        </Typography>

        <div className={classes.navbar}>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 30,
              marginBottom: 10,
            }}
          >
            {ship.name}
          </Typography>
          <div className={classes.buttons}>
            <Button
              className={
                location.pathname === match.url + "/calendrier"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/calendrier"
                      ? require("../Icons/calendar_white.png")
                      : require("../Icons/calendar_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/calendrier");
              }}
            >
              Calendrier
            </Button>
            <Button
              className={
                location.pathname === match.url + "/cahier"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/cahier"
                      ? require("../Icons/tools_white.png")
                      : require("../Icons/tools_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/cahier");
              }}
            >
              Cahier machine
            </Button>
            <Button
              className={
                location.pathname === match.url + "/hydrocarbure"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 20, height: 25 }}
                  src={
                    location.pathname === match.url + "/hydrocarbure"
                      ? require("../Icons/hydro_white.png")
                      : require("../Icons/hydro_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/hydrocarbure");
              }}
            >
              Hydrocarbure
            </Button>
            <Button
              className={
                location.pathname === match.url + "/maintenance"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/maintenance"
                      ? require("../Icons/boat_white.png")
                      : require("../Icons/boat_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/maintenance");
              }}
            >
              Maintenance réglementaire
            </Button>
            <Button
              className={
                location.pathname === match.url + "/infos"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/infos"
                      ? require("../Icons/anchor_white.png")
                      : require("../Icons/anchor_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/infos");
              }}
            >
              Navire et équipage
            </Button>
            <Button
              className={
                location.pathname === match.url + "/storage"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <StorageIcon
                  style={{
                    width: 25,
                    height: 25,
                    color:
                      location.pathname === match.url + "/storage"
                        ? "#FFF"
                        : "#00638F",
                  }}
                />
              }
              onClick={() => {
                history.push(match.url + "/storage");
              }}
            >
              Stockage
            </Button>
            <Button
              className={
                location.pathname === match.url + "/horodatage"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <AccessTime
                  style={{
                    width: 25,
                    height: 25,
                    color:
                      location.pathname === match.url + "/horodatage"
                        ? "#FFF"
                        : "#00638F",
                  }}
                />
              }
              onClick={() => {
                history.push(match.url + "/horodatage");
              }}
            >
              Horodatage moteurs
            </Button>
            <Button
              className={
                location.pathname === match.url + "/checklist"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <CheckCircleOutlineIcon
                  style={{
                    width: 25,
                    height: 25,
                  }}
                />
              }
              onClick={() => {
                history.push(match.url + "/checklist");
              }}
            >
              Checklists
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => setOpenReport(true)}
            >
              Rapport
            </Button>
          </div>
        </div>

        <div
          style={{
            padding: "5% 0",
            backgroundColor: "#FCFCFC",
          }}
        >
          <Switch>
            <Route exact path={match.url + "/cahier"}>
              <CahierTableau firebase={firebase} shipId={shipId} />
            </Route>
            <Route exact path={match.url + "/hydrocarbure"}>
              <HydrocarbureTableau firebase={firebase} shipId={shipId} />
            </Route>
            <Route exact path={match.url + "/maintenance"}>
              <MaintenanceTableau firebase={firebase} shipId={shipId} />
            </Route>
            <Route exact path={match.url + "/calendrier"}>
              <MyCalendar firebase={firebase} shipId={shipId} />
            </Route>
            <Route exact path={match.url + "/infos"}>
              <InfosRecap firebase={firebase} shipId={shipId} ship={ship} />
            </Route>
            <Route exact path={match.url + "/horodatage"}>
              <Horodatage firebase={firebase} shipId={shipId} ship={ship} />
            </Route>
            <Route exact path={match.url + "/checklist"}>
              <Checklist firebase={firebase} shipId={shipId} ship={ship} />
            </Route>
            <Route exact path={match.url + "/storage"}>
              <Storage firebase={firebase} shipId={shipId} ship={ship} />
            </Route>
          </Switch>
          <Dialog
            open={openReport}
            onClose={() => setOpenReport(false)}
            fullWidth
          >
            <DialogTitle>
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#00638F",
                  marginBottom: 0,
                }}
              >
                Rapport pro format
              </h2>
            </DialogTitle>
            <DialogContent>
              <p>Sur quelle période souhaitez vous éditer un rapport ?</p>
              <FinalForm onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <p style={{ margin: 10 }}>du</p>
                  <InputText name="startDate" type="date" required />
                  <p style={{ margin: 10 }}>au</p>
                  <InputText name="endDate" type="date" required />
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? "Génération en cours... " : "Générer un rapport"}
                </Button>
                {pdf && (
                  <PDFDownloadLink
                    document={<Rapport {...pdf} />}
                    fileName="rapport.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        {loading
                          ? "Chargement du document"
                          : "Télécharger le rapport"}
                      </Button>
                    )}
                  </PDFDownloadLink>
                )}
              </FinalForm>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setOpenReport(false)}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  );
}
