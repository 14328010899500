import {
  Button,
  makeStyles,
  Modal,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import InputSelect from "../../Inputs/InputSelect";
import Container from "@material-ui/core/Container";
import InputText from "../../Inputs/InputText";
import FinalForm from "../../Utils/FinalForm";
import { Email, Language, Phone, Photo } from "@material-ui/icons";
import Compressor from "compressorjs";
import { useCookies } from "react-cookie";

const InfosRecap = ({ firebase, shipId, ship }) => {
  const classes = useStyles();
  const [sailors, setSailors] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ref = firebase.db.collection("Sailors");
  const [open, setOpen] = useState(false);
  const [coques, setCoques] = useState([]);
  const [activities, setActivities] = useState([]);
  const [photo, setPhoto] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [pathName, setPathName] = useState("");
  const [cookies, setCookie] = useCookies();
  const history = useHistory();

  const handleUpload = async () => {
    if (!uploadFile) {
      return ship.image || "";
    }
    const fileRef = firebase.storage
      .ref()
      .child(`${cookies.userToken}/${shipId}/${uploadFile.name}`);

    await fileRef.put(uploadFile);

    const url = await fileRef.getDownloadURL();

    return url;
  };

  const handleSubmit = async (values) => {
    const url = await handleUpload();

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .update({
        name: values?.name || "",
        MMSI: values?.MMSI || "",
        immatriculation: values?.immatriculation || "",
        width: values?.width || "",
        length: values?.length || "",
        draught: values?.draught || "",
        port: values?.port || "",
        jauge: values?.jauge || "",
        motorisation: values?.motorisation || "",
        power: values?.power || "",
        auxiliary: values?.auxiliary || "",
        navigationCategory: values?.navigationCategory || "",
        constructionYear: values?.constructionYear || "",
        maxCapacity: values?.maxCapacity || "",
        coque: values?.coque || "",
        activityType: values?.activityType || "",
        radio: values?.radio || "",
        image: url || "",
      })
      .then(() => {
        enqueueSnackbar("Navire modifié", { variant: "success" });
        setOpen(false);
      });
  };

  const onFileChange = (event) => {
    setPhoto(URL.createObjectURL(event.target.files[0]));
    setUploadFile(event.target.files[0]);
  };

  const openConfirmDelete = (sailor) => {
    setConfirmDeleteOpen(sailor.id);
  };

  const closeConfirmDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const handleDelete = () => {
    ref
      .doc(confirmDeleteOpen)
      .delete()
      .then(() => {
        getSailors();
        enqueueSnackbar("Marin supprimé", {
          variant: "success",
        });
        setConfirmDeleteOpen(false);
      });
  };

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">
        Êtes-vous sûr de vouloir supprimer ce profil ?
      </h2>
      <div className={classes.confirmBtn}>
        <p
          className={classes.yesBtn}
          id="simple-modal-description"
          onClick={() => {
            handleDelete(confirmDeleteOpen);
          }}
        >
          Oui
        </p>
        <p
          className={classes.noBtn}
          id="simple-modal-description"
          onClick={closeConfirmDelete}
        >
          Non
        </p>
      </div>
    </div>
  );

  function getSailors() {
    ref
      .where("ship", "==", shipId)
      .get()
      .then((docs) => {
        let tmp = [];
        docs.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        console.log(tmp);
        setSailors(tmp);
      });
  }
  useEffect(() => {
    getSailors();
    firebase.db
      .collection("ActivityTypes")
      .get()
      .then((snapshot) => {
        let tmp = [];

        snapshot.forEach((item) => {
          tmp.push({
            label: item.data().value,
            id: item.data().value,
          });
        });
        console.log("activities", tmp);

        setActivities(tmp);
      });
    firebase.db
      .collection("CoqueTypes")
      .get()
      .then((snapshot) => {
        let tmp = [];

        snapshot.forEach((item) => {
          tmp.push({
            label: item.data().value,
            id: item.data().value,
          });
        });
        console.log("coques", tmp);
        setCoques(tmp);
      });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#FCFCFC",
        fontFamily: "Poppins",
        padding: "0 5%",
      }}
    >
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={confirmDeleteOpen}
        onClose={closeConfirmDelete}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div className={classes.rowBig3}>
        {sailors.map((sailor) => {
          return (
            <div className={classes.card}>
              <svg
                style={{ fontSize: 45 }}
                class="MuiSvgIcon-root jss174"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill="#00638F"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
                ></path>
              </svg>
              <p>Nom : {sailor.name}</p>
              <p>Identifiant : {sailor.id}</p>
              <div className={classes.twoBtnsCard}>
                <Link
                  to={"#"}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    openConfirmDelete(sailor);
                  }}
                >
                  <svg
                    style={{
                      backgroundColor: "#ff5a5f",
                      borderRadius: "50%",
                      padding: 2,
                      marginTop: 20,
                    }}
                    class="MuiSvgIcon-root jss174"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill="#ffffff"
                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                    ></path>
                  </svg>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/ship/${shipId}/sailor/${sailor.id}`}
                >
                  <svg
                    style={{
                      backgroundColor: "#00638F",
                      borderRadius: "50%",
                      padding: 3,
                      marginTop: 20,
                      marginLeft: 15,
                    }}
                    class="MuiSvgIcon-root jss174"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill="#ffffff"
                      d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          );
        })}
        {ship.accessLevel > 0 && !!ship.accessLevel && (
          <div className={classes.card}>
            <Link
              to={"/" + shipId + "/sailors"}
              style={{ textDecoration: "none" }}
            >
              <svg
                style={{ fontSize: 45 }}
                class="MuiSvgIcon-root jss174"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill="#329646"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                ></path>
              </svg>
              <p style={{ marginTop: 5 }}>Ajouter un </p>
              <p>marin</p>
            </Link>
          </div>
        )}
      </div>
      <div className={classes.rowBig}>
        <div className={classes.colBig2}>
          {ship.accessLevel && (
            <div className={classes.button}>
              <div
                style={{
                  padding: 30,
                  paddingBottom: 10,
                  paddingTop: 10,
                  borderRadius: 50,
                  cursor: "pointer",
                  backgroundColor: "#00638F",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => setOpen(true)}
              >
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 13,
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Modifier
                </Typography>
              </div>
            </div>
          )}

          <div className={classes.boatInfo1}>
            <img
              style={{
                borderRadius: "50%",
                width: 85,
                height: 85,
                objectFit: "cover",
                backgroundPosition: "center",
              }}
              src={
                ship.image
                  ? ship.image
                  : "https://cdn.pixabay.com/photo/2019/02/17/09/04/marina-4001846_1280.jpg"
              }
              alt=""
            />
            <h2 style={{ fontWeight: 400, color: "#00638F" }}>{ship.name}</h2>

            <div className={classes.boatInfoLine}>
              <span className={classes.denomination}>Immatriculation :</span>
              <p className={classes.textValue}>{ship.immatriculation}</p>
            </div>
          </div>
          <div className={classes.boatInfo2}>
            <div className={classes.smallLine}>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Largeur (m):</span>
                <span className={classes.textValue}>{ship.width}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Longueur (m):</span>
                <span className={classes.textValue}>{ship.length}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Type d'activité :</span>
                <span className={classes.textValue}>{ship.activityType}</span>
              </div>
            </div>
            <div className={classes.smallLine}>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Tirant d'eau (m):</span>
                <span className={classes.textValue}>{ship.draught}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Port d'attache :</span>
                <span className={classes.textValue}>{ship.port}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Jauge brut :</span>
                <span className={classes.textValue}>{ship.jauge}</span>
              </div>
            </div>
            <div className={classes.smallLine}>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Motorisation :</span>
                <span className={classes.textValue}>{ship.motorisation}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>
                  Puissance propulsive (kW):
                </span>
                <span className={classes.textValue}>{ship.power}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>
                  Moteur auxiliaire :
                </span>
                <span className={classes.textValue}>{ship.auxiliary}</span>
              </div>
            </div>
            <div className={classes.smallLine}>
              <div className={classes.colLine}>
                <span className={classes.denomination}>
                  Année de construction :
                </span>
                <span className={classes.textValue}>
                  {ship.constructionYear}
                </span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Numéro MMSI :</span>
                <span className={classes.textValue}>{ship.MMSI}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>
                  Catégorie de navigation :
                </span>
                <span className={classes.textValue}>
                  {ship.navigationCategory}
                </span>
              </div>
            </div>
            <div className={classes.smallLine}>
              <div className={classes.colLine} style={{ width: "50%" }}>
                <span className={classes.denomination}>Type de coque :</span>
                <span className={classes.textValue}>{ship.coque}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>
                  Nombre maximal de personnes à bord :
                </span>
                <span className={classes.textValue}>{ship.maxCapacity}</span>
              </div>
              <div className={classes.colLine}>
                <span className={classes.denomination}>Indicatif radio :</span>
                <span className={classes.textValue}>{ship.radio}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setPhoto(ship.image);
        }}
        fullWidth
      >
        <FinalForm onSubmit={handleSubmit} initialValues={ship}>
          <DialogTitle>
            <h2
              style={{ fontWeight: "bold", color: "#00638F", marginBottom: 0 }}
            >
              Modifier les informations
            </h2>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                className={classes.submit}
                name="image"
              >
                Modifier la photo
                <input
                  type="file"
                  name="image"
                  hidden
                  accept="image/*"
                  onChange={onFileChange}
                />
              </Button>
              {!!photo ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  className={classes.btnWrapper}
                >
                  <img
                    src={photo}
                    style={{
                      maxWidth: "80%",
                      maxHeight: "80%",
                      objectFit: "contain",
                      marginTop: 10,
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  className={classes.btnWrapper}
                >
                  <img
                    src={
                      ship.image
                        ? ship.image
                        : "https://cdn.pixabay.com/photo/2019/02/17/09/04/marina-4001846_1280.jpg"
                    }
                    style={
                      !ship.image
                        ? {
                            maxWidth: "50%",
                            maxHeight: "50%",
                            objectFit: "contain",
                            marginTop: 10,
                          }
                        : {
                            maxWidth: "80%",
                            maxHeight: "80%",
                            objectFit: "contain",
                            marginTop: 10,
                          }
                    }
                  />
                </div>
              )}
            </div>
            <Container className={classes.container}>
              <div className={classes.field}>
                <Typography className={classes.label}>Nom</Typography>
                <InputText name="name" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Immatriculation
                </Typography>
                <InputText name="immatriculation" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>Largeur (m)</Typography>
                <InputText name="width" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>Longueur (m)</Typography>
                <InputText name="length" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Tirant d'eau (m)
                </Typography>
                <InputText name="draught" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Port d'attache
                </Typography>
                <InputText name="port" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>Jauge brut</Typography>
                <InputText name="jauge" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>Motorisation</Typography>
                <InputText name="motorisation" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Puissance propulsive (kW)
                </Typography>
                <InputText name="power" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Moteur auxiliaire
                </Typography>
                <InputText name="auxiliary" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Catégorie de navigation
                </Typography>
                <InputText name="navigationCategory" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Année de construction
                </Typography>
                <InputText name="constructionYear" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>Numéro MMSI</Typography>
                <InputText name="MMSI" />
              </div>

              <Typography className={classes.label}>
                Nombre maximal de personnes à bord
              </Typography>
              <InputText name="maxCapacity" />

              <div className={classes.field}>
                <Typography className={classes.label}>Type de coque</Typography>
                <InputSelect content={coques} name="coque" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Type d'activité
                </Typography>
                <InputSelect content={activities} name="activityType" />
              </div>
              <div className={classes.field}>
                <Typography className={classes.label}>
                  Indicatif radio
                </Typography>
                <InputText name="radio" />
              </div>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Annuler
            </Button>

            <Button type="submit">Enregistrer</Button>
          </DialogActions>{" "}
        </FinalForm>
      </Dialog>
      <div className={classes.rowBig2}>
        <p
          style={{
            display: "inline-block",
            margin: "0 45px 0 0",
            color: "#747779",
          }}
        >
          L'équipe est à votre écoute
        </p>
        <div className={classes.contacts}>
          <div className={classes.contact}>
            <Email color="primary" />
            <a
              style={{
                display: "inline-block",
                margin: 0,
                marginLeft: 5,
                color: "#00638F",
              }}
              href="mailto:maint@andsea.fr"
            >
              maint@andsea.fr
            </a>
          </div>
          <div className={classes.contact}>
            <Phone color="primary" />
            <a
              style={{
                display: "inline-block",
                margin: 0,
                marginLeft: 5,
                color: "#00638F",
              }}
              href="sms:+33787374228"
            >
              07 87 37 42 28 (WhatsApp)
            </a>
          </div>
          <div className={classes.contact}>
            <Language color="primary" />
            <a
              style={{
                display: "inline-block",
                margin: 0,
                marginLeft: 5,
                color: "#00638F",
              }}
              href="https://maintandsea.fr/"
            >
              maintandsea.fr
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  rowBig: {
    minHeight: 550,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  rowBig2: {
    backgroundColor: "#ffffff",
    height: 150,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
    boxShadow: "0 0 0.75rem rgba(0, 0, 0, .05)",
    marginTop: 5,
  },
  rowBig3: {
    backgroundColor: "none",
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "stretch",
    marginBottom: 5,
  },
  card: {
    backgroundColor: "#ffffff",
    minHeight: 150,
    width: 150,
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
    boxShadow: "0 0 0.75rem rgba(0, 0, 0, .05)",
    margin: 10,
    padding: "0 15px",
    textAlign: "center",
  },
  colBig2: {
    backgroundColor: "#ffffff",
    height: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "collumn",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: 0,
    padding: "30px 30px",
    borderRadius: 25,
    boxShadow: "0 0 0.75rem rgba(0, 0, 0, .05)",
  },
  container: {
    padding: 15,
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  field: {
    width: "48%",
    marginBottom: 10,
  },
  label: {
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: 23,
    color: "#00638F",
  },
  button: {
    position: "absolute",
    top: 20,
    left: 30,
    width: 140,
  },
  twoBtnsCard: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
  },
  userInfos: {
    height: "80%",
    width: "30%",
    backgroundColor: "#ffffff",
    position: "absolute",
    bottom: 0,
    borderRadius: 25,
    boxShadow: "0 0 0.75rem rgba(0, 0, 0, .05)",
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  userInfos1: {
    textAlign: "center",
  },
  userInfos2: {
    padding: "0 20px",
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    marginBottom: 16,
    fontSize: 11,
  },
  greyText: {
    color: "#747779",
  },
  boatInfo1: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boatInfo2: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  boatInfoLine: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  denomination: {
    color: "#747779",
  },
  textValue: {
    color: "#00638F",
    marginRight: 20,
  },
  smallLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginBottom: 20,
  },
  colLine: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#00638F",
    color: "#ffffff",
    boxShadow: theme.shadows[5],
    padding: "35px 40px",
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  confirmBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    marginTop: 30,
  },
  yesBtn: {
    padding: "6px 35px",
    borderRadius: 25,
    backgroundColor: "#ffffff",
    textAlign: "center",
    color: "#00638F",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  noBtn: {
    padding: "6px 35px",
    borderRadius: 25,
    backgroundColor: "none",
    border: "1px solid #ffffff",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },

  list: {
    width: 450,
    padding: "30px 40px",
    backgroundColor: "#00638F",
    height: "100vh",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    color: "#ffffff",
  },
  submit: {
    color: "#ffffff",
    borderRadius: 30,
    backgroundColor: "#11455c !important",
    marginTop: 30,
  },
  formImput: {
    color: "#ffffff !important",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  contacts: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  contact: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));
export default InfosRecap;
