import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect } from "react-router-dom";
import logoApp from "./../Icons/icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#388ADC",
  },
}));

export default function SignInSide({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onSubmit = (values) => {
    if (!values.email) {
      enqueueSnackbar("Merci de renseigner un email !", {
        variant: "error",
      });
    }
    if (values.password < 3) {
      enqueueSnackbar("Merci de renseigner un mot de passe !", {
        variant: "error",
      });
    }
    firebase
      .doSignInWithEmailAndPassword(values.email, values.password)
      .then((success) => {
        firebase.db
          .collection("Admins")
          .doc(success.user.email)
          .get()
          .then((snapshot) => {
            if (!snapshot.exists) {
              enqueueSnackbar(
                "Le mot de passe ou l'adresse mail est invalide",
                {
                  variant: "error",
                }
              );
              return;
            }
            setCookie("userToken", success.user.uid, {
              path: "/",
              maxAge: 86400,
            });
            setCookie("email", values.email, {
              path: "/",
              maxAge: 86400,
            });
          });
      })
      .catch((error) => {
        enqueueSnackbar("Le mot de passe ou l'adresse mail est invalide", {
          variant: "error",
        });
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      {cookies.userToken && <Redirect to="/users" />}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img src={logoApp} style={{ height: "50%", aspectRatio: 1 }} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{
              marginTop: 50,
              fontSize: 60,
              color: "#388ADC",
            }}
          >
            Prospection commerciale
          </Typography>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#388ADC",
            }}
          >
            Connexion
          </Typography>
          <FinalForm onSubmit={onSubmit} style={classes.form}>
            <InputText name="email" label="Adresse mail" required></InputText>
            <InputText
              name="password"
              label="Mot de passe"
              type="password"
              required
            ></InputText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Se connecter
              </Button>
            </div>
          </FinalForm>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item></Grid>
          </Grid>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              Prospection commerciale
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
