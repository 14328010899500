import { Container, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";
import ImageViewer from "react-simple-image-viewer";
const CahierItem = ({ firebase, item }) => {
  const classes = useStyles();
  const [openPhoto, setOpenPhoto] = useState(false);

  return (
    <>
      <Container className={classes.container}>
        <div className={classes.field}>
          <Typography className={classes.label}>Date</Typography>
          <Typography>Le {moment(item.date).format("DD/MM/YYYY")}</Typography>
        </div>
        {item.type !== "HYDROCARBURES" && (
          <>
            <div className={classes.field}>
              <Typography className={classes.label}>Poste</Typography>
              <Typography>{item.type}</Typography>
            </div>
            <div className={classes.fullField}>
              <Typography className={classes.label}>Action</Typography>
              <Typography>{item.title}</Typography>
            </div>
            <div className={classes.fullField}>
              <Typography className={classes.label}>
                Détail de l'action
              </Typography>
              <ul className={classes.list}>
                {item.descriptions.map((desc, i) => {
                  return <li className={classes.listItem}>{desc.label}</li>;
                })}
              </ul>
            </div>
          </>
        )}{" "}
        {/* {item.type === "MACHINE" && ( */}
        <div className={classes.field}>
          <Typography className={classes.label}>Équipement</Typography>
          <Typography>{item.equipement}</Typography>
        </div>
        {/* )} */}
        {item.type === "HYDROCARBURES" && (
          <>
            <div className={classes.field}>
              <Typography className={classes.label}>Port</Typography>
              <Typography>{item.port}</Typography>
            </div>
            <div className={classes.field}>
              <Typography className={classes.label}>Hydrocarbure</Typography>
              <Typography>{item.hydrocarbure}</Typography>
            </div>
            <div className={classes.field}>
              <Typography className={classes.label}>Quantité</Typography>
              <Typography>{item.quantity + " " + item.quantityUnit}</Typography>
            </div>
            <div className={classes.field}>
              <Typography className={classes.label}>
                Prix total (en €)
              </Typography>
              <Typography>{item.unitPrice}</Typography>
            </div>
          </>
        )}
        <div className={classes.field}>
          <Typography className={classes.label}>Moteur</Typography>
          <Typography>{item.moteur}</Typography>
        </div>
        <div className={classes.field}>
          <Typography className={classes.label}>Heures moteur</Typography>
          <Typography>{item.horodatage}</Typography>
        </div>
        <div className={classes.field}>
          <Typography className={classes.label}>Périodicité</Typography>
          <Typography>
            {item.recurrence + " " + item.recurrence_unit}
          </Typography>
        </div>
        {item.recurrence_unit == "Heures compteur" && (
          <div className={classes.field}>
            <Typography className={classes.label}>Heure échéance</Typography>
            <Typography>{item.dueBy}</Typography>
          </div>
        )}
        <div className={classes.fullField}>
          <Typography className={classes.label}>Commentaire</Typography>
          <Typography>{item.descriptionDetail}</Typography>
        </div>
        {!!item.image ? (
          <div className={classes.fullField}>
            <Typography className={classes.label}>Photo</Typography>
            <img
              src={item.image}
              className={classes.photo}
              style={{ cursor: "zoom-in" }}
              onClick={() => setOpenPhoto(true)}
            />
          </div>
        ) : (
          <div className={classes.fullField}>
            <Typography className={classes.label}>Pas de photo</Typography>
          </div>
        )}
        <div className={classes.fullField}>
          <Typography className={classes.label}>
            Dernière modification
          </Typography>
          <Typography>
            Le{" "}
            {moment(item?.lastModified?.toDate() || Date.now()).format(
              "DD/MM/YYYY [à] HH:mm:ss"
            )}{" "}
            par {item.modifiedBy}
          </Typography>
        </div>
      </Container>
      {openPhoto && (
        <ImageViewer
          src={[item.image]}
          currentIndex={0}
          disableScroll
          closeOnClickOutside
          onClose={() => setOpenPhoto(false)}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  field: {
    width: "48%",
    marginBottom: 10,
    border: "1px solid rgb(181, 181, 181)",
    borderRadius: 10,
    padding: 15,
  },
  fullField: {
    width: "98%",
    marginBottom: 10,
    border: "1px solid rgb(181, 181, 181)",
    borderRadius: 10,
    padding: 15,
  },
  label: {
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: 23,
    color: "#00638F",
  },
  list: {
    margin: 0,
    paddingLeft: 15,
  },
  listItem: {
    fontSize: "1rem",
  },
  photo: {
    objectFit: "contain",
    width: "80%",
    height: "80%",
    margin: "auto",
  },
}));

export default CahierItem;
