import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  makeStyles,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Visibility, Edit } from "@material-ui/icons";
import MaintenanceItem from "./MaintenanceItem";
import { Link, useHistory } from "react-router-dom";

const MaintenanceTableau = ({ firebase, shipId }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [confirmEvent, setConfirmEvent] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [pastLoaded, setPastLoaded] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!pastLoaded && search) {
      setLoading(true);
      firebase.db
        .collection("Ships")
        .doc(shipId)
        .collection("event_maintenance")

        .orderBy("date", "asc")
        .get()
        .then((snapshot) => {
          let tmp = [];
          snapshot.forEach((doc) => {
            tmp.push({ ...doc.data(), id: doc.id });
          });
          setEvents((prev) => [...prev, ...tmp]);
          setLoading(false);
        });
      setPastLoaded(true);
    }
  }, [search]);

  const history = useHistory();
  useEffect(() => {
    setConfirmEvent(false);
    setDeleteEvent(false);
  }, [selectedEvent]);

  const fetchEvents = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .where("date", ">=", moment().subtract(1, "week").format("YYYY/MM/DD"))
      .orderBy("date", "asc")
      .get()
      .then((snapshot) => {
        let tmp = [];
        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        setEvents((prev) => tmp);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .doc(selectedEvent.id)
      .delete()
      .then(() => {
        enqueueSnackbar("Évènement supprimé !", { variant: "success" });
        fetchEvents();
        setSelectedEvent(null);
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la suppression", { variant: "error" });
      });
  };

  const handleConfirm = async () => {
    let unit;
    if (selectedEvent.recurrence_unit === "Mois") {
      unit = "months";
    } else if (selectedEvent.recurrence_unit === "Semaine") {
      unit = "weeks";
    } else if (selectedEvent.recurrence_unit === "Année") {
      unit = "years";
    } else if (selectedEvent.recurrence_unit === "Jours") {
      unit = "days";
    } else {
      unit = false;
    }

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .doc(selectedEvent.id)
      .update({
        validated: true,
      })

      .then(() => {
        enqueueSnackbar("Évènement validé !", { variant: "success" });
        fetchEvents();
        setSelectedEvent((prev) => ({ ...prev, validated: true }));
        if (unit && selectedEvent.recurrence) {
          firebase.db
            .collection("Ships")
            .doc(shipId)
            .collection("event_maintenance")
            .add({
              ...selectedEvent,
              validated: false,
              date: moment(selectedEvent.date)
                .add(unit, selectedEvent.recurrence)
                .format("YYYY/MM/DD"),
            });
        }
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la validation", { variant: "error" });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [events, setEvents] = useState([]);
  useEffect(() => {
    fetchEvents();
  }, []);

  const withFilters = (item) => {
    if (search == "") {
      return !item.validated;
    }
    if (item.type?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.title?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.descriptionDetail?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.recurrence?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.recurrence_unit?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (
      moment(item.date)
        .format("DD/MM/YYYY")
        .toLowerCase()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (item.descriptions) {
      item.descriptions.map((e) => {
        if (e.category?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (e.label?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (e.value?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
      });
    }

    return false;
  };

  return (
    <Container>
      <div className={classes.SearchAndCardWrapper}>
        <TextField
          className={classes.searchbar}
          variant="outlined"
          placeholder="Recherchez tous vos événéments antérieurs"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  style={{ width: 25, height: 25 }}
                  src={require("../../Icons/search.png")}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Link
          to={"/" + shipId + "/addMaintenance"}
          style={{ textDecoration: "none" }}
          className={classes.card}
        >
          <svg
            style={{ fontSize: 45 }}
            class="MuiSvgIcon-root jss174"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill="#329646"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
            ></path>
          </svg>
        </Link>
      </div>
      <TableContainer>
        {loading && <CircularProgress color="primary" />}
        <Table aria-label="simple table" className={classes.head}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.col}>DATE</TableCell>
              <TableCell className={classes.col}>STATUT</TableCell>
              <TableCell className={classes.col}>POSTE</TableCell>
              <TableCell className={classes.col}>ACTION</TableCell>
              <TableCell className={classes.col}>DÉTAILS</TableCell>
              <TableCell className={classes.col}>COMMENTAIRE</TableCell>
              <TableCell className={classes.col}>VOIR PLUS</TableCell>
              <TableCell className={classes.col}>MODIFIER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events
              .filter((item) => withFilters(item))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell>{moment(row.date).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>
                    {row.validated ? "Terminé" : "En attente"}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    {row.descriptions.map((e) => e.value).join(", ")}
                  </TableCell>
                  <TableCell>{row.descriptionDetail}</TableCell>
                  <TableCell className={classes.col}>
                    <Visibility
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedEvent(row);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      to={"/" + shipId + `/${row.id}` + "/editMaintenance"}
                      style={{ textDecoration: "none", color: "inherit" }}
                      className={classes.card}
                    >
                      <Edit style={{ cursor: "pointer" }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={events.filter((item) => withFilters(item)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page"
      />
      {!!selectedEvent && (
        <Dialog
          open={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          fullWidth
        >
          <DialogTitle>
            <h2
              style={{ fontWeight: "bold", color: "#00638F", marginBottom: 0 }}
            >
              Évènement{" "}
              {selectedEvent.validated ? "validé" : "en attente de validation"}
            </h2>
          </DialogTitle>
          <MaintenanceItem firebase={firebase} item={selectedEvent} />

          <DialogActions>
            <Button onClick={() => setSelectedEvent(null)}>Fermer</Button>

            {!selectedEvent.validated && (
              <>
                <Button onClick={() => setDeleteEvent(true)}>Supprimer</Button>
                <Button
                  onClick={() =>
                    history.push(
                      "/" + shipId + "/maintenance/" + selectedEvent.id
                    )
                  }
                >
                  Modifier
                </Button>
                <Button onClick={() => setConfirmEvent(true)}>
                  Valider l'évènement
                </Button>
              </>
            )}
          </DialogActions>
          <Dialog
            open={confirmEvent}
            onClose={() => setConfirmEvent(false)}
            fullWidth
          >
            <DialogTitle>Attention</DialogTitle>
            <DialogContent>
              <Typography>
                Voulez vous vraiment valider cet évènement ? Il ne sera plus
                possible de le modifier ni de le supprimer
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmEvent(false)}>Annuler</Button>

              <Button onClick={handleConfirm}>Valider l'évènement</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteEvent}
            onClose={() => setDeleteEvent(false)}
            fullWidth
          >
            <DialogTitle>Attention</DialogTitle>
            <DialogContent>
              <Typography>
                Voulez vous vraiment supprimer cet évènement ? Cette action est
                irréversible
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteEvent(false)}>Annuler</Button>

              <Button onClick={handleDelete}>Supprimer l'évènement</Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  col: {
    color: "#00638F",
    borderBottomWidth: 0,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#DCEFF6",
    },
  },
  searchbar: {
    display: "flex",
    borderRadius: 50,
    width: "60%",
    margin: "10px auto",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    padding: "0 15px",
    textAlign: "center",
  },
  SearchAndCardWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default MaintenanceTableau;
